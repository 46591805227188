import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ search }: any) => {
  let params = '?';

  if (search) {
    params += `name=${search}`;
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
