import React from 'react';
import styled from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { GridElem } from '../../common/grid';
import { DATA_INTER, USER_EARNING_ITEM_DATA_INTER } from './constant';
import { Spacing } from '../../theme';
import { CardEarning } from './frame/card-earning';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { TextElem } from '../../common/text';
import { EmptyListUserElem } from '../../common/empty-list-user-elem';
import { PaginationElem } from '../../common/pagination';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isLoadingBalanceData?: boolean;
  errorMessage?: string;
  pageSize: number;
  page: number;
  onChangePage: (arg: number) => void;
  pageCount: number;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  isLoadingBalanceData,
  pageSize,
  page,
  onChangePage,
  pageCount,
}) => {
  if (isLoadingBalanceData) {
    return <></>;
  }

  return (
    <GridElemStyled spacing={4}>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      <TextElem
        size="medium"
        color="white"
        type="semi-bold"
        tid="EARNING.USER.LIST.TITLE"
      />
      {isLoading && <SkeletonDataStyled />}

      <GridStyled>
        {data?.isEmpty && !isLoading && <EmptyListUserElem />}
        {isSuccess &&
          data?.list &&
          !data.isEmpty &&
          data?.list.map((item: USER_EARNING_ITEM_DATA_INTER) => (
            <CardEarning data={item} />
          ))}
      </GridStyled>
      {!data?.isEmpty && (
        <PaginationStyled
          defaultPageSize={pageSize}
          current={page}
          onChange={onChangePage}
          total={pageCount}
        />
      )}
    </GridElemStyled>
  );
};

const PaginationStyled = styled(PaginationElem)`
  position: absolute;
  bottom: ${Spacing(33)};
  left: 50%;
  transform: translateX(-50%);
`;

const SkeletonDataStyled = styled(SkeletonDataElem)`
  margin-top: ${Spacing(6)};
`;

const GridElemStyled = styled(GridElem)`
  margin-top: ${Spacing(1)};
  margin-bottom: ${Spacing(22)};
`;

const GridStyled = styled(GridElem)`
  grid-auto-columns: auto max-content max-content;
  row-gap: ${Spacing(5)};
`;
