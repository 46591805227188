import React from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { ALERT_ACTION_ENUM, ALERT_ACTION_TYPE, ALERT_ICON } from './constants';

import './style.css';

interface ElemProps {
  text?: string;
  tid?: string;
  tvalue?: object;
  type?: ALERT_ACTION_TYPE;
  open?: boolean;
}

export const Elem: React.FC<ElemProps> = ({
  text,
  tid,
  tvalue,
  type = ALERT_ACTION_ENUM.ERROR,
  open = true,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (open) {
      message.open({
        content: tid ? t(tid, tvalue) : text,
        duration: 2.5,
        className: `custom-message ${type}`,
        icon: <img src={ALERT_ICON[type]} alt="alert icon" />,
      });
    }
  }, [open, text, tid, tvalue, type, t]);

  return null;
};
