import React, { useEffect, useMemo, useState } from 'react';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { convert } from './convert';
import { usePaginatedData } from '../../lib/util/usePagintationData';

export const Container: React.FC<{
  payload?: {
    status: string;
    type?: string;
    userId?: string | number;
    startDate?: string;
    endDate?: string;
  };
}> = ({ payload }) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const pageSize = 10;

  const onSuccess = (data: any) => {
    const totalPage = data?.pages[0].total;

    if (totalPage) {
      setPageCount(totalPage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [payload]);

  const {
    data: preFetch,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = usePaginatedData(
    action,
    MODULE_NAME,
    {
      pageParam: page,
      pageSize,
      ...payload,
    },
    onSuccess,
  );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const withdrawList = useMemo(() => {
    const list = preFetch?.pages[0].list as unknown as any;

    if (list) {
      const convertedList = convert(list);

      return { ...convertedList };
    }
  }, [preFetch]);

  // const getData = (): DATA_INTER | undefined => {
  //   const data = preFetch.data as unknown as ACTION_RESPONSE_INTER;

  //   if (data) {
  //     const convertedData = convert(data);

  //     return { ...convertedData, isEmpty: data?.list?.length < 1 };
  //   }
  // };

  const getErrorMessage = () => {
    const errorObj: ACTION_ERROR_INTER = error as ACTION_ERROR_INTER;

    if (error) {
      return errorObj.message;
    }
  };

  return (
    <Component
      data={withdrawList}
      isLoading={isLoading || isFetching}
      isError={isError && !isLoading}
      isSuccess={isSuccess && !isFetching}
      errorMessage={getErrorMessage()}
      pageSize={pageSize}
      page={page}
      onChangePage={handlePageChange}
      pageCount={pageCount}
    />
  );
};
