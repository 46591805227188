import React from 'react';
import styled from 'styled-components';

import IconLink from '../../../asset/svg/button/link.svg';
import {
  EVENT_CATEGORY_VIEW,
  EVENT_ITEM_DATA_RAW_INTER,
  EVENT_TAG,
  EVENT_TAG_VIEW,
} from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { ButtonElem } from '../../../common/button';
import { CopyElem } from '../../../common/copy';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { COLOR_ENUM } from '../../../theme/color';
import { getTelegramUserData } from '../../../lib/util/telegramData';
import { USER_ID_INTER } from '../../../data/user/constant';

export const Card: React.FC<EVENT_ITEM_DATA_RAW_INTER & USER_ID_INTER> = ({
  title,
  description,
  link,
  imgUrl,
  tag,
  category,
  id,
  utmParam,
  telegramId,
  invitedTelegramId,
}) => {
  const telegramUserData = getTelegramUserData();

  const user = telegramUserData?.user || {};
  const telegramIdByUser = user?.id || telegramId || '';
  const telegramIdByInvited = invitedTelegramId ? `_${invitedTelegramId}` : '';

  const linkEvent = `${link}?${utmParam}=${telegramIdByUser}${telegramIdByInvited}`;

  console.log('linkEvent', linkEvent);

  return (
    <Container>
      <ImgContainer>
        <Img src={imgUrl} />
        {tag && (
          <Tag tagId={tag}>
            <TextElem
              size="verySmall"
              type="medium"
              color="white"
              tid={EVENT_TAG_VIEW[tag]}
            />
          </Tag>
        )}
        {category && (
          <Category>
            <TextElem
              size="verySmall"
              type="medium"
              color="textPrimary"
              tid={EVENT_CATEGORY_VIEW[category]}
            />
          </Category>
        )}
      </ImgContainer>
      <InfoContaoner>
        <TextElem color="white" size="semiSmall" type="semi-bold" tid={title} />
        <TextElemStyled
          color="textSecondary"
          size="verySmall"
          type="medium"
          tid={description}
        />
        <CopyElem value={linkEvent}>
          <ButtonElem type="copy" iconRight={IconLink} tid="COMMON.COPY_TEXT" />
        </CopyElem>
      </InfoContaoner>
    </Container>
  );
};

const ImgContainer = styled.div`
  position: relative;
`;

const Category = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
  padding: ${Spacing(2)} 22px;
  position: absolute;
  bottom: ${Spacing(3)};
  right: ${Spacing(2)};
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(100px);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tag = styled.div<{ tagId: number }>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
  position: absolute;
  padding: ${Spacing(2)} 19px;
  bottom: ${Spacing(3)};
  left: ${Spacing(2)};
  background-color: ${({ theme, tagId }) =>
    tagId && tagId === EVENT_TAG.NEW
      ? theme[COLOR_ENUM.TAG_NEW]
      : theme[COLOR_ENUM.TAG_SECONDARY]};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextElemStyled = styled(TextElem)`
  margin-bottom: ${Spacing(1)};
`;

const InfoContaoner = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const Img = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  min-height: ${Spacing(40)};
  max-height: 160px;
  object-fit: cover;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
`;
