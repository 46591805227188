export const DATA: { label: string; children: string }[] = [
  {
    label: 'FAQ.QUESTION1.label',
    children: 'FAQ.QUESTION1.content',
  },
  {
    label: 'FAQ.QUESTION2.label',
    children: 'FAQ.QUESTION2.content',
  },
  {
    label: 'FAQ.QUESTION3.label',
    children: 'FAQ.QUESTION3.content',
  },
  {
    label: 'FAQ.QUESTION4.label',
    children: 'FAQ.QUESTION4.content',
  },
  {
    label: 'FAQ.QUESTION5.label',
    children: 'FAQ.QUESTION5.content',
  },
  {
    label: 'FAQ.QUESTION6.label',
    children: 'FAQ.QUESTION6.content',
  },
  {
    label: 'FAQ.QUESTION7.label',
    children: 'FAQ.QUESTION7.content',
  },
  {
    label: 'FAQ.QUESTION8.label',
    children: 'FAQ.QUESTION8.content',
  },
  {
    label: 'FAQ.QUESTION9.label',
    children: 'FAQ.QUESTION9.content',
  },
  {
    label: 'FAQ.QUESTION10.label',
    children: 'FAQ.QUESTION10.content',
  },
  {
    label: 'FAQ.QUESTION11.label',
    children: 'FAQ.QUESTION11.content',
  },
];
