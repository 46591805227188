import React from 'react';

import { i18n } from '../../lib/lang';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';

export const Component: React.FC<{
  isSubmitDisabled: Function;
  isError?: boolean;
  isSuccess?: boolean;
  errorMessage?: string;
  handleUpdate: Function;
  isLoading?: boolean;
}> = ({
  isSubmitDisabled,
  isError,
  errorMessage,
  isSuccess,
  handleUpdate,
  isLoading,
}) => {
  return (
    <>
      <ButtonElem
        type="submit"
        tid="COMMON.BUTTON.SAVE"
        disabled={isSubmitDisabled()}
        onClick={(e: any) => {
          e.stopPropagation();
          handleUpdate();
        }}
      />
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </>
  );
};
