import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export interface FETCH_EARNING_PARAM_INTER {
  pageParam?: number;
  pageSize?: number;
  status?: 'All' | string;
  userId?: string | number;
  eventId?: string | number;
  startDate?: string | number;
  endDate?: string | number;
}

export const action = async ({
  pageParam,
  pageSize,
  status,
  userId,
  eventId,
  startDate,
  endDate,
}: FETCH_EARNING_PARAM_INTER): Promise<any> => {
  let params = '?';

  if (pageParam) {
    params += `page=${pageParam}&`;
  }

  if (pageSize) {
    params += `pageSize=${pageSize}&`;
  }

  if (status && status !== 'All') {
    params += `status=${status}&`;
  }

  if (userId) {
    params += `userId=${userId}&`;
  }

  if (eventId) {
    params += `eventId=${eventId}&`;
  }

  if (startDate && endDate) {
    params += `startDate=${startDate}&endDate=${endDate}&`;
  }

  params.slice(0, -1);

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
