import { USER_GIFT } from '../../data/user/constant';
import {
  USER_WITHDRAW_STATUS,
  USER_WITHDRAW_TYPE,
} from '../withdraw-list/constant';

export const MODULE_NAME = 'WITHDRAW_USER_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `withdraw/user-withdraw-list/${params}`,
};

export interface WITHDRAW_ITEM_DATA_RAW_INTER {
  id: string;
  amount: string;
  status: USER_WITHDRAW_STATUS;
  address: string;
  type: USER_WITHDRAW_TYPE;
  createDate: string;
  gift: USER_GIFT;
  wallet: string;
}

export interface WITHDRAW_ITEM_LIST_DATA_RAW_INTER {
  list: WITHDRAW_ITEM_DATA_RAW_INTER[];
}

export interface WITHDRAW_ITEM_DATA_INTER
  extends Omit<WITHDRAW_ITEM_DATA_RAW_INTER, 'status'> {
  status: {
    label: string;
    value: string;
  };
}

export interface WITHDRAW_ITEM_LIST_DATA_INTER {
  list?: WITHDRAW_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface DATA_INTER extends WITHDRAW_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER
  extends WITHDRAW_ITEM_LIST_DATA_RAW_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
