import React from 'react';

import { Redirect, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { LayoutElem } from '../../common/layout';
import { ToolbarMenuContainer } from '../toolbar-menu';
import { LayoutPage } from '../../common/layout-page';
import { HeaderContainer } from '../header';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';
import { WITHOUT_TOOLBAR_PAGE } from '../toolbar-menu/constant';
import { EVENT_PAGE_PATH } from '../../page/event';
import { PROFILE_UPDATE_PAGE_PATH } from '../../page/profile-update';

export const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);

  const currentPath = location.pathname;

  const isToolbarMenu = (path: string) => {
    return !WITHOUT_TOOLBAR_PAGE.some((p) => currentPath.startsWith(p));
  };

  const isEmail = !!state.user?.email;

  return (
    <>
      <LayoutPage>
        <LayoutElem direction="column">
          <HeaderContainer />
          {children}
        </LayoutElem>
        {isToolbarMenu(currentPath) && <ToolbarMenuContainer />}
      </LayoutPage>
      {isEmail ? (
        <Redirect to={EVENT_PAGE_PATH} />
      ) : (
        <Redirect to={PROFILE_UPDATE_PAGE_PATH} />
      )}
    </>
  );
};
