import { USER_GIFT } from '../../data/user/constant';
import {
  DATA_INTER,
  GIFT_DATA,
  GIFT_DATA_ENUM,
  GIFT_DETAIL,
  GIFT_ITEM_DATA_RAW_INTER,
} from './constant';

export const convert = (
  data: GIFT_ITEM_DATA_RAW_INTER,
): DATA_INTER | undefined => {
  
  if (!data) {
    return;
  }

  const converedData = data?.list.map((e: USER_GIFT) => {
    const convert = GIFT_DATA[e];
    return convert as unknown as GIFT_DETAIL;
  });

  return { list: converedData, isEmpty: data?.list?.length < 1 };
};
