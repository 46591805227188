import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const updateData = (
  id?: string,
  formData?: {
    level?: number;
    role?: number;
  },
) => {
  return HttpRequest({
    method: API.UPDATE.TYPE,
    url: API.UPDATE.URL(id),
    data: { ...formData },
  });
};
