export const MODULE_NAME = 'WITHDRAW_GIFT_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const API = {
  TYPE: 'POST',
  URL: 'withdraw/create-withdraw',
};

export enum FORM_VALUE_ENUM {
  ADDRESS = 'address',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.ADDRESS]: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
