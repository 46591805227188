import React from 'react';
import styled, { css } from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { i18n } from '../../lib/lang';
import { DATA_INTER } from '../user-gift-analytic/constant';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import giftSvg from '../../asset/svg/common/gift-user-white.svg';
import { LEVEL_GIFT, LEVEL_PERSON_AMOUNT } from '../../data/level/constant';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isError, errorMessage }) => {
  const level = data?.level ? data.level : 0;

  const currentLevel = LEVEL_PERSON_AMOUNT.find((l) => l.level === level);

  return (
    <Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      <TextElemStyled
        tid="USER.GIFT.TITLE_LIST"
        type="regular"
        size="alert"
        color="textPrimary"
      />
      <LvlWrapper>
        <ProgressContainer>
          {LEVEL_PERSON_AMOUNT.map((e, index) => {
            const isCurrentLvl = e.level === Number(currentLevel?.level);
            const isCompletedLvl = Number(currentLevel?.level) > e.level;

            return (
              <ProgressChunk>
                <Dot
                  isCompletedLvl={isCompletedLvl}
                  isCurrentLvl={isCurrentLvl}
                />
                <ProgressLine
                  isLastLine={index === LEVEL_PERSON_AMOUNT.length - 1}
                  isCompletedLvl={isCompletedLvl}
                  isCurrentLvl={isCurrentLvl}
                />
              </ProgressChunk>
            );
          })}
        </ProgressContainer>
        <GiftList>
          {LEVEL_PERSON_AMOUNT.map((e, index) => {
            const giftCount = index;

            const isLevelGift = LEVEL_GIFT[e.level]
              ? !!LEVEL_GIFT[e.level]
              : null;

            const isCurrentLvl =
              Number(e.level) === Number(currentLevel?.level);

            return (
              <Card isCurrentLvl={isCurrentLvl} isLevelGift={!!isLevelGift}>
                <TextContainer>
                  <TextElem
                    color="default"
                    type="semi-bold"
                    size="alert"
                    tid={`USER.GIFT.LEVEL.${giftCount}.TITLE_SHORT`}
                  />
                  <InfoCard>
                    <TextElem
                      type="medium"
                      size="alert"
                      color="textPrimary"
                      tid={`USER.GIFT.LEVEL.${giftCount}.AMOUNT`}
                    />
                    <TextElem
                      type="semi-bold"
                      size="alert"
                      color="textPrimary"
                      tid={`USER.GIFT.LEVEL.${giftCount}.REF`}
                    />
                  </InfoCard>
                  {isLevelGift && (
                    <InfoCard>
                      <img src={giftSvg} />
                      <TextElem
                        size="small"
                        type="medium"
                        color="textPrimary"
                        tid={`USER.GIFT.LEVEL.${giftCount}.GIFT`}
                      />
                    </InfoCard>
                  )}
                </TextContainer>
                {isLevelGift && (
                  <ImgStyled
                    src={i18n.t(`USER.GIFT.LEVEL.${giftCount}.PHOTO`)}
                  />
                )}
              </Card>
            );
          })}
        </GiftList>
      </LvlWrapper>
    </Container>
  );
};

const ProgressChunk = styled.div`
  display: flex;
  align-items: start;
  justify-items: center;
  justify-content: center;
  position: relative;
`;

const Dot = styled.span<{ isCurrentLvl: boolean; isCompletedLvl: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: absolute;
  background-color: ${({ theme, isCompletedLvl }) =>
    !isCompletedLvl
      ? theme[COLOR_ENUM.TEXT_SECONDARY]
      : theme[COLOR_ENUM.WHITE]};

  ${({ isCurrentLvl }) => {
    if (isCurrentLvl) {
      return css`
        background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
      `;
    }
  }};
`;

const ProgressLine = styled.div<{
  isCurrentLvl: boolean;
  isCompletedLvl: boolean;
  isLastLine: boolean;
}>`
  width: 2px;
  border-radius: 2px;
  height: 111px;

  ${({ isCurrentLvl }) => {
    if (isCurrentLvl) {
      return css`
        background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
      `;
    }
  }}

  ${({ isCompletedLvl, isLastLine, theme }) => {
    if (isLastLine) {
      return css`
        background-color: unset;
      `;
    } else {
      return css`
        background-color: ${!isCompletedLvl
          ? theme[COLOR_ENUM.DIVIDER]
          : theme[COLOR_ENUM.DEFAULT]};
      `;
    }
  }}
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: ${Spacing(8)};
`;

const TextElemStyled = styled(TextElem)`
  text-align: center;
`;

const InfoCard = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const ImgStyled = styled.img`
  width: 88px;
  height: 88px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
`;

const LvlWrapper = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing(5)};
`;

const GiftList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
  width: 100%;
`;

const Card = styled.div<{ isLevelGift?: boolean; isCurrentLvl: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr ${Spacing(22)};
  padding: ${({ isLevelGift }) =>
    !isLevelGift ? `${Spacing(5)} ${Spacing(3)}` : Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  width: 100%;

  ${({ isCurrentLvl }) => {
    if (isCurrentLvl) {
      return css`
        border: 1px solid ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
        background-color: unset;
      `;
    }
  }}
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;
