import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { COLOR_ENUM } from '../../theme/color';
import { Pagination, PaginationProps } from 'antd';
import { Spacing } from '../../theme';
import arrowLeft from '../../asset/svg/common/arrow-left-pagination.svg';
import arrowRight from '../../asset/svg/common/arrow-right-pagination.svg';
import { VALUE_OPACITY_DATA, VALUE_OPACITY_ENUM } from '../../theme/value';

export const Elem: React.FC<PaginationProps> = ({ ...props }) => {
  const [showLessItems, setShowLessItems] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 400px)');

    const handleResize = (e: MediaQueryListEvent) => {
      setShowLessItems(e.matches);
    };

    // Установка значения при первой загрузке
    setShowLessItems(mediaQuery.matches);

    // Подписка на изменения ширины экрана
    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return <img src={arrowLeft} alt="previous" />;
    }
    if (type === 'next') {
      return <img src={arrowRight} alt="next" />;
    }
    return originalElement;
  };

  return (
    <PaginationStyled
      showLessItems={showLessItems}
      showPrevNextJumpers={false}
      showSizeChanger={false}
      hideOnSinglePage
      itemRender={itemRender}
      {...props}
    />
  );
};

const PaginationStyled = styled(Pagination)`
  display: flex;
  align-items: center;

  .ant-pagination-item.ant-pagination-item-active {
    background: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]} !important;
    min-width: ${Spacing(10)} !important;
    min-height: ${Spacing(10)} !important;
    border-radius: ${Spacing(2)} !important;
    border: unset !important;

    & &:hover {
      background: unset !important;
      border-radius: ${Spacing(2)};
    }

    a {
      font-weight: 600;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: unset;
      color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
    }
  }

  .ant-pagination-item {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
    min-width: ${Spacing(10)} !important;
    min-height: ${Spacing(10)} !important;
    border-radius: ${Spacing(2)};
    border: unset !important;

    & &:hover {
      background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
      border-radius: ${Spacing(2)};
    }

    a {
      font-weight: 600;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: unset;
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]}y;
    }
  }

  .ant-pagination-disabled {
    opacity: 0.8;
  }
`;
