import React from 'react';
import { Component } from './component';
import { useSelector } from 'react-redux';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';

export const Container: React.FC<{}> = ({}) => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);

  const isAdmin = () => state.user?.role === USER_ROLE.ADMIN;
  const isEmail = !!state.user?.email;

  return <Component isEmail={isEmail} isAdmin={isAdmin()} />;
};
