export const DATA = [
  {
    title: 'EDUCATION.LIST.0.TITLE',
    description: 'EDUCATION.LIST.0.DESCRIPTION',
    video: 'EDUCATION.LIST.0.VIDEO',
    img: 'EDUCATION.LIST.0.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.0.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.0.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.0.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.0.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.0.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.0.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.0.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.0.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.0.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.0.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.0.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.0.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.0.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.0.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.0.LINK.4.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.1.TITLE',
    description: 'EDUCATION.LIST.1.DESCRIPTION',
    video: 'EDUCATION.LIST.1.VIDEO',
    img: 'EDUCATION.LIST.1.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.1.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.1.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.1.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.1.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.1.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.1.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.1.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.1.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.1.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.1.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.1.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.1.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.1.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.1.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.1.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.1.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.1.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.1.LINK.5.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.2.TITLE',
    description: 'EDUCATION.LIST.2.DESCRIPTION',
    video: 'EDUCATION.LIST.2.VIDEO',
    img: 'EDUCATION.LIST.2.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.2.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.2.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.2.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.2.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.2.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.2.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.2.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.2.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.2.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.2.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.2.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.2.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.2.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.2.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.2.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.2.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.2.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.2.LINK.5.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.3.TITLE',
    description: 'EDUCATION.LIST.3.DESCRIPTION',
    video: 'EDUCATION.LIST.3.VIDEO',
    img: 'EDUCATION.LIST.3.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.3.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.3.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.3.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.3.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.3.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.3.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.3.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.3.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.3.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.3.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.3.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.3.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.3.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.3.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.3.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.3.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.3.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.3.LINK.5.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.4.TITLE',
    description: 'EDUCATION.LIST.4.DESCRIPTION',
    video: 'EDUCATION.LIST.4.VIDEO',
    img: 'EDUCATION.LIST.4.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.4.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.4.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.4.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.4.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.4.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.4.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.4.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.4.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.4.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.4.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.4.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.4.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.4.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.4.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.4.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.4.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.4.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.4.LINK.5.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.5.TITLE',
    description: 'EDUCATION.LIST.5.DESCRIPTION',
    video: 'EDUCATION.LIST.5.VIDEO',
    img: 'EDUCATION.LIST.5.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.5.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.5.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.5.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.5.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.5.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.5.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.5.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.5.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.5.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.5.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.5.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.5.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.5.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.5.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.5.LINK.4.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.6.TITLE',
    description: 'EDUCATION.LIST.6.DESCRIPTION',
    video: 'EDUCATION.LIST.6.VIDEO',
    img: 'EDUCATION.LIST.6.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.6.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.6.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.6.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.6.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.6.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.6.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.5.URL',
      },
      {
        title: 'EDUCATION.LIST.6.LINK.6.TITLE',
        buttonTitle: 'EDUCATION.LIST.6.LINK.6.BUTTON_TITLE',
        url: 'EDUCATION.LIST.6.LINK.6.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.7.TITLE',
    description: 'EDUCATION.LIST.7.DESCRIPTION',
    video: 'EDUCATION.LIST.7.VIDEO',
    img: 'EDUCATION.LIST.7.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.7.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.7.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.7.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.7.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.7.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.7.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.7.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.7.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.7.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.7.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.7.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.7.LINK.3.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.8.TITLE',
    description: 'EDUCATION.LIST.8.DESCRIPTION',
    video: 'EDUCATION.LIST.8.VIDEO',
    img: 'EDUCATION.LIST.8.IMG',
    link: [],
  },
  {
    title: 'EDUCATION.LIST.9.TITLE',
    description: 'EDUCATION.LIST.9.DESCRIPTION',
    video: 'EDUCATION.LIST.9.VIDEO',
    img: 'EDUCATION.LIST.9.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.9.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.9.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.9.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.9.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.9.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.9.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.9.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.9.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.9.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.9.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.9.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.9.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.9.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.9.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.9.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.9.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.9.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.9.LINK.5.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.10.TITLE',
    description: 'EDUCATION.LIST.10.DESCRIPTION',
    video: 'EDUCATION.LIST.10.VIDEO',
    img: 'EDUCATION.LIST.10.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.10.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.10.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.10.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.10.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.10.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.10.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.5.URL',
      },
      {
        title: 'EDUCATION.LIST.10.LINK.6.TITLE',
        buttonTitle: 'EDUCATION.LIST.10.LINK.6.BUTTON_TITLE',
        url: 'EDUCATION.LIST.10.LINK.6.URL',
      },
    ],
  },
  {
    title: 'EDUCATION.LIST.11.TITLE',
    description: 'EDUCATION.LIST.11.DESCRIPTION',
    video: 'EDUCATION.LIST.11.VIDEO',
    img: 'EDUCATION.LIST.11.IMG',
    link: [
      {
        title: 'EDUCATION.LIST.11.LINK.0.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.0.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.0.URL',
      },
      {
        title: 'EDUCATION.LIST.11.LINK.1.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.1.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.1.URL',
      },
      {
        title: 'EDUCATION.LIST.11.LINK.2.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.2.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.2.URL',
      },
      {
        title: 'EDUCATION.LIST.11.LINK.3.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.3.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.3.URL',
      },
      {
        title: 'EDUCATION.LIST.11.LINK.4.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.4.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.4.URL',
      },
      {
        title: 'EDUCATION.LIST.11.LINK.5.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.5.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.5.URL',
      },
      {
        title: 'EDUCATION.LIST.11.LINK.6.TITLE',
        buttonTitle: 'EDUCATION.LIST.11.LINK.6.BUTTON_TITLE',
        url: 'EDUCATION.LIST.11.LINK.6.URL',
      },
    ],
  },
];
