export const MODULE_NAME = 'GIFT_PICK_UP_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const API = {
  TYPE: 'PATCH',
  URL: (giftId: number) => `user/exchange-gift/${giftId}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
