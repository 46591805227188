import React, { useState } from 'react';
import styled from 'styled-components';

import { FieldTextElem } from '../../../common/field-text';
import CloseIcon from '../../../asset/svg/common/close.svg';
import CloseWhite from '../../../asset/svg/common/close-white.svg';
import SearchIcon from '../../../asset/svg/common/search.svg';
import SearchIconWhite from '../../../asset/svg/common/search-white.svg';

import { ButtonElem } from '../../../common/button';

export const Search: React.FC<{ search: string; setSearch: Function }> = ({
  search,
  setSearch,
}) => {
  const handleClose = () => {
    setLocalSearch('');
    setSearch('');
  };
  const [localSearch, setLocalSearch] = useState(search);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: any) => {
    setLocalSearch(e.target.value);

    setSearch(e.target.value);
  };

  const searchIcon = isFocused ? SearchIconWhite : SearchIcon;
  const closeIcon = isFocused ? CloseWhite : CloseIcon;

  return (
    <Container
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <FieldText
        searchIcon={searchIcon}
        value={localSearch}
        onChange={handleChange}
        errorContainer={false}
        placeholder="COMMON.FILTER.SEARCH"
      />
      {localSearch && (
        <Button
          color="white"
          iconLeft={closeIcon}
          fill="clear"
          onClick={handleClose}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Button = styled(ButtonElem)`
  position: absolute;
  width: max-content;
  background-color: unset !important;

  &:hover {
    border: none;
  }

  top: 14px;
  right: 46px;
  padding: 0;
`;

const FieldText = styled(FieldTextElem)<{ searchIcon: string }>`
  #content {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 2px;
    right: 0;
    background: ${({ searchIcon }) => `url(${searchIcon})`} no-repeat;
    background-position: top 50% right 16px;
    background-size: 20px;
  }

  width: 100%;
`;
