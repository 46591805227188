import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA_INTER, WITHDRAW_ITEM_DATA_INTER } from './constant';
// import { UserItemElem } from '../../data/user/frame/user-item-list.elem ';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { CardWithdraw } from './frame/card-withdraw';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { EmptyListElem } from '../../common/empty-list-elem';
import { PaginationElem } from '../../common/pagination';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  pageSize: number;
  page: number;
  onChangePage: (arg: number) => void;
  pageCount: number;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  pageSize,
  page,
  onChangePage,
  pageCount,
}) => {
  const isUpdateDisabled = isLoading || isError;
  const [openCardId, setOpenCardId] = useState<string | null>(null);

  const handleCardClick = (id: string) => {
    setOpenCardId((prevOpenCardId) => (prevOpenCardId === id ? null : id));
  };

  const hanldeCloseModal = () => {
    setOpenCardId(null);
  };

  return (
    <GridElemStyled>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      <GridStyled>
        {data?.isEmpty && !isLoading && (
          <EmptyListElem
            titleTid="WITHDRAW.LIST.IS_EMPTY_TITLE"
            descriptionTid="WITHDRAW.LIST.IS_EMPTY_DESCRIPTION"
          />
        )}
        {isSuccess &&
          data?.list &&
          !data.isEmpty &&
          data?.list.map((item: WITHDRAW_ITEM_DATA_INTER) => (
            <>
              <Divider />
              <CardWithdraw
                isOpen={openCardId === item.id}
                hanldeCloseModal={hanldeCloseModal}
                onClick={() => handleCardClick(item.id)}
                isUpdateDisabled={isUpdateDisabled}
                data={item}
              />
            </>
          ))}
      </GridStyled>
      {isLoading && (
        <>
          <Divider />
          <SkeletonDataStyled />
        </>
      )}
      {!data?.isEmpty && (
        <PaginationStyled
          defaultPageSize={pageSize}
          current={page}
          onChange={onChangePage}
          total={pageCount}
        />
      )}
    </GridElemStyled>
  );
};

const PaginationStyled = styled(PaginationElem)`
  position: absolute;
  bottom: ${Spacing(33)};
  left: 50%;
  transform: translateX(-50%);
`;

const SkeletonDataStyled = styled(SkeletonDataElem)`
  margin-top: ${Spacing(6)};
`;

const GridElemStyled = styled(GridElem)`
  margin-top: ${Spacing(1)};
  margin-bottom: ${Spacing(22)};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  height: 1px;
  width: 100%;
`;

const GridStyled = styled(GridElem)`
  grid-auto-columns: auto max-content max-content;
  row-gap: ${Spacing(1)};
`;
