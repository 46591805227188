export const MODULE_NAME = 'ACCOUNT_PROFILE_MODULE_NAME';
export const MODULE_NAME_PHOTO = 'PHOTO_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: `/project/list`,
  PHOTO_URL: 'user/photo',
};

export interface ACTION_RESPONSE_INTER {}

export interface DATA_INTER {
  first_name: string;
  id: string;
  last_name: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
