import CalendarImg from '../../asset/svg/common/calendar.svg';
import InfoImg from '../../asset/svg/common/info-circle.svg';
import TeacherImg from '../../asset/svg/common/teacher.svg';
import WalletImg from '../../asset/svg/common/empty-wallet.svg';
import ActivityImg from '../../asset/svg/common/activity.svg';

import CalendarActiveImg from '../../asset/svg/common/calendar-active.svg';
import InfoActiveImg from '../../asset/svg/common/info-circle-active.svg';
import TeacherActiveImg from '../../asset/svg/common/teacher-active.svg';
import WalletActiveImg from '../../asset/svg/common/empty-wallet-active.svg';
import ActivityActiveImg from '../../asset/svg/common/activity-active.svg';

import PeopleImg from '../../asset/svg/common/people.svg';
import ReceiptImg from '../../asset/svg/common/receipt.svg';
import RequestImg from '../../asset/svg/common/requests.svg';

import PeopleActiveImg from '../../asset/svg/common/people-active.svg';
import ReceiptActiveImg from '../../asset/svg/common/receipt-active.svg';
import RequestActiveImg from '../../asset/svg/common/requests-active.svg';
import AdditionalActiveImg from '../../asset/svg/common/additional-active.svg';
import AdditionalImg from '../../asset/svg/common/additional.svg';

import { ANALYTIC_PAGE_PATH } from '../../page/analytic';
import { EVENT_PAGE_PATH } from '../../page/event';
import { BALANCE_PAGE_PATH } from '../../page/balance';
import { EDUCATION_PAGE_PATH } from '../../page/education';
import { ACTIVITY_PAGE_PAGE_PATH } from '../../page/activity';
import { USER_ADMIN_PAGE_PATH } from '../../page/admin-user';
import { ADDITIONAL_PAGE_PATH } from '../../page/additional';
import { FAQ_PAGE_PATH } from '../../page/faq';
import { EARNING_ADMIN_PAGE_PATH } from '../../page/admin-earning';
import { WITHDRAW_PAGE_PATH } from '../../page/admin-withdraw';
import { EVENT_ADMIN_PAGE_PATH } from '../../page/admin-event';
import { CREATE_WITHDRAW_GIFT_PAGE_PATH } from '../../page/create-withdraw-gift';
import { CREATE_WITHDRAW_WALLET_PAGE_PATH } from '../../page/create-withdraw-wallet';
import { PROFILE_UPDATE_PAGE_PATH } from '../../page/profile-update';
import { LEVEL_GIFT_PAGE_PATH } from '../../page/level-gift';

export const ADMIN_PAGE = [
  EARNING_ADMIN_PAGE_PATH,
  USER_ADMIN_PAGE_PATH,
  EVENT_ADMIN_PAGE_PATH,
  ADDITIONAL_PAGE_PATH,
  WITHDRAW_PAGE_PATH,
];

export const WITHOUT_TOOLBAR_PAGE = [
  PROFILE_UPDATE_PAGE_PATH,
  LEVEL_GIFT_PAGE_PATH,
  CREATE_WITHDRAW_WALLET_PAGE_PATH,
  CREATE_WITHDRAW_GIFT_PAGE_PATH,
];

export const MODULE_NAME = 'MENU_MODULE_NAME';
export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const data: any = {
  user: [
    {
      img: CalendarImg,
      imgActive: CalendarActiveImg,
      tid: 'NAVIGATION.TAB_APP.EVENT',
      path: EVENT_PAGE_PATH,
    },
    {
      img: ActivityImg,
      imgActive: ActivityActiveImg,
      tid: 'NAVIGATION.TAB_APP.ANALYTIC',
      path: ANALYTIC_PAGE_PATH,
    },

    {
      img: WalletImg,
      imgActive: WalletActiveImg,
      tid: 'NAVIGATION.TAB_APP.BALANCE',
      path: BALANCE_PAGE_PATH,
    },
    {
      img: TeacherImg,
      imgActive: TeacherActiveImg,
      tid: 'NAVIGATION.TAB_APP.TEACHING',
      path: EDUCATION_PAGE_PATH,
    },
    {
      img: InfoImg,
      imgActive: InfoActiveImg,
      tid: 'NAVIGATION.TAB_APP.FAQ',
      path: FAQ_PAGE_PATH,
    },
  ],
  admin: [
    {
      img: ReceiptImg,
      imgActive: ReceiptActiveImg,
      tid: 'NAVIGATION.TAB_APP.RECEIPT',
      path: EARNING_ADMIN_PAGE_PATH,
    },
    {
      img: CalendarImg,
      imgActive: CalendarActiveImg,
      tid: 'NAVIGATION.TAB_APP.EVENTS_ADMIN',
      path: EVENT_ADMIN_PAGE_PATH,
    },
    {
      img: PeopleImg,
      imgActive: PeopleActiveImg,
      tid: 'NAVIGATION.TAB_APP.USERS',
      path: USER_ADMIN_PAGE_PATH,
    },
    {
      img: RequestImg,
      imgActive: RequestActiveImg,
      tid: 'NAVIGATION.TAB_APP.REQUEST',
      path: WITHDRAW_PAGE_PATH,
    },
    {
      img: AdditionalImg,
      imgActive: AdditionalActiveImg,
      tid: 'NAVIGATION.TAB_APP.ADDITIONAL',
      path: ADDITIONAL_PAGE_PATH,
    },
  ],
};

export interface ACTION_RESPONSE_INTER {}

export interface DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
