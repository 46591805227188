import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => ({
  ...data,
  skill: data.skill.map((e: any) => Number(e.value)),
  instagram: data[FORM_VALUE_ENUM.INSTAGRAM].toLocaleLowerCase(),
  email: data[FORM_VALUE_ENUM.EMAIL].toLocaleLowerCase(),
  discord: data[FORM_VALUE_ENUM.DISCORD].toLocaleLowerCase(),
});

export const convertArray = (
  array: string[] | null | any,
  viewObj: Record<string, string>,
) =>
  array
    ? array.map((e: number) => ({
        label: viewObj[Number(e)],
        value: Number(e),
      }))
    : null;
