import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { SIZE_LAYOUT_DATA, SIZE_LAYOUT_ENUM } from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';

import { PROPS_TYPE } from './constant';

export const Elem: React.FC<PROPS_TYPE> = ({
  open,
  onClose,
  children,
  closeAction,
  className,
}) => {
  const onCloseAction = () => {
    onClose();
    closeAction && closeAction();
  };

  const handleClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onCloseAction();
    }
  };

  if (!open) return null;

  return (
    <ModalOverlay onClick={handleClose} className={className}>
      <ModalContent open={open}>{children}</ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 18, 26, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: ${Spacing(5)};
`;

const ModalContent = styled.div<{ open: boolean }>`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  padding: ${Spacing(5)};
  border-radius: 16px;
  max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
  width: 450px;
  transform: translateY(
    ${({ open }: { open: boolean }) => (open ? '0' : '20px')}
  );
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;

  position: relative;
`;
