import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PhoneInput from 'react-phone-input-2';

import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';
import CalendarIcon from '../../asset/svg/common/calendar.svg';

export const Elem: React.FC<PROPS_TYPE> = ({
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  type,
  disabled,
  icon,
  placeholder,
  className,
  maxLength,
  min = '1',
  max,
  errorContainer = true,
  autoFocus = false,
  defaultValue,
  isRequired,
}) => {
  const isSuccess = !!value && !error;

  const refIcon = useRef<HTMLDivElement>(null);
  const handleChange = (e: any) => {
    if (type === 'phone') {
      if (e && onChange && e.length < 14) {
        onChange({ target: { name: name, value: `+${e}` } });
      }
    } else {
      if (!e.target) e.target = {};
      e.target.name = name;
      e.target.value = maxLength
        ? e.target.value.substr(0, maxLength)
        : e.target.value;

      if (onChange) {
        onChange(e);
      }
    }
  };

  const handleBlur = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onBlur) {
      onBlur(e);
    }
  };

  const iconSize = refIcon?.current?.clientWidth || 40;
  if (disabled) {
    return (
      <Container>
        {title && <TextElem tid={title} type="medium" color="textPrimary" />}
        <FakeInput isIcon={!!icon}>
          <TextElem
            color="textDisabled"
            tid={value || i18n.t(placeholder || '')}
          />
          <Icon disabled={disabled} ref={refIcon}>
            {icon && icon}
          </Icon>
        </FakeInput>
      </Container>
    );
  } else {
    return (
      <Container className={className}>
        {title && (
          <TitleContainer>
            <TextElem
              tid={title}
              size="semiSmall"
              type="regular"
              color="textSecondary"
            />
            {isRequired && (
              <TextElem tid="*" size="semiSmall" type="regular" color="error" />
            )}
          </TitleContainer>
        )}
        <Content>
          {type === 'phone' ? (
            <PhoneInputCustom
              isSuccess={isSuccess}
              countryCodeEditable={true}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value}
              inputProps={{
                name: name,
              }}
            />
          ) : (
            <RelativeDiv>
              <CustomInput
                min={min}
                isIcon={!!icon}
                isSuccess={isSuccess}
                name={name}
                onChange={handleChange}
                defaultValue={defaultValue}
                onBlur={handleBlur}
                error={error}
                type={type}
                value={value}
                iconSize={iconSize}
                placeholder={placeholder && i18n.t(placeholder)}
                className={className}
                maxLength={maxLength}
                max={max}
                autoFocus={autoFocus}
              />
              <div id="content" />
              <Icon ref={refIcon}>{icon && icon}</Icon>
            </RelativeDiv>
          )}

          {errorContainer && (
            <>
              {(error || errorMessage) && (
                <ErrorContainer className="errorContainer">
                  <TextElem size="small" color="error">
                    {errorMessage}
                  </TextElem>
                </ErrorContainer>
              )}
            </>
          )}
        </Content>
      </Container>
    );
  }
};

const TitleContainer = styled.div`
  display: inline-block;
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const CustomInput = styled.input<{
  error?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize: number;
  isSuccess: boolean;
  isIcon?: boolean;
}>`
  cursor: pointer;

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};

  padding: ${({ isIcon }) => (isIcon ? '12px 16px 12px 28px' : '12px 16px')};
  padding-right: ${({ iconSize }) => iconSize}px;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  line-height: 1em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  width: 100%;

  transition: all 0.2s;

  border: 1px solid
    ${({ theme, isSuccess }) =>
      isSuccess
        ? theme[COLOR_ENUM.BORDER_DEFAULT]
        : theme[COLOR_ENUM.BORDER_DEFAULT]};

  &:focus {
    border: double 1px transparent;
    background-image: linear-gradient(#1b1b1c, #1b1b1c),
      linear-gradient(to right, #f4e187, #e5b45f);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  ::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]} !important;
    font-size: 14px !important;
  }

  &:hover {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};

    ::placeholder {
      display: none;
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]} !important;
    }
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ error }) =>
    error &&
    css`
      /* border-color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important; */
    `}

  ::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0); */
    opacity: 1;
    display: block;
    background: url(${CalendarIcon}) no-repeat;
    width: 16px;
    height: 16px;
  }

  :-webkit-autofill {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
    border: 1px solid
      ${({ theme, isSuccess }) =>
        isSuccess
          ? theme[COLOR_ENUM.BORDER_DEFAULT]
          : theme[COLOR_ENUM.BORDER_DEFAULT]} !important;
    box-shadow: 0 0 0px 1000px
      ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]} inset !important;
  }
`;

const PhoneInputCustom = styled(PhoneInput)<{ isSuccess: boolean }>`
  && div {
    display: none;
  }
  && input {
    padding: 12px 16px;

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    line-height: 1em;
    text-align: start;
    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
    width: 100%;

    &:focus {
      border: double 1px transparent;
      background-image: linear-gradient(#1b1b1c, #1b1b1c),
        linear-gradient(to right, #f4e187, #e5b45f);
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }

    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]} !important;
      font-size: 14px !important;
    }

    &:hover {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};

      ::placeholder {
        display: none;
        color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]} !important;
      }
    }

    border: 1px solid
      ${({ theme, isSuccess }) =>
        isSuccess
          ? theme[COLOR_ENUM.BORDER_DEFAULT]
          : theme[COLOR_ENUM.BORDER_DEFAULT]};

    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
    }

    &:hover {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};

      ::placeholder {
        display: none;
        color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
      }
    }

    :-webkit-autofill {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
      color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      border: 1px solid
        ${({ theme, isSuccess }) =>
          isSuccess
            ? theme[COLOR_ENUM.BORDER_DEFAULT]
            : theme[COLOR_ENUM.BORDER_DEFAULT]} !important;
      box-shadow: 0 0 0px 1000px
        ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]} inset !important;
    }
  }
`;

const FakeInput = styled.div<{ isIcon?: boolean; iconSize?: number }>`
  padding: ${({ isIcon }) => (isIcon ? '12px 16px 12px 28px' : '12px 16px')};
  padding-right: ${({ iconSize }) => iconSize}px;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  line-height: 1em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  opacity: 0.5;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  position: relative;
  width: 100%;

  overflow: hidden;
  display: flex;
  align-items: center;

  && > span {
    width: calc(100% - 40px);
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const Content = styled.div`
  position: relative;
`;

const ErrorContainer = styled.div`
  margin-top: ${Spacing(2)};
  position: relative;
  line-height: 1em;
  text-align: start;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  text-align: start;
  width: 100%;
`;

const Icon = styled.div<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  font-size: 14px;

  opacity: ${({ disabled }) => disabled && '0.2'};

  left: 16px;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  }
`;
