import React, { useState } from 'react';
import { FormikValues, useFormik } from 'formik';

import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import { FORM_VALUE_ENUM, FORM_VALUE_TYPE } from './constant';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { createEvent } from './action';
import { validation } from '../../lib/validation';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  maxLength,
  required,
  url,
  urlOptional,
} from '../../lib/validation/service';
import { ACTION_ERROR_INTER } from '../event-list/constant';
import { convert } from './convert';
import { EVENT_ADMIN_LIST_MODULE_NAME } from '../admin-event-list';

export const Container: React.FC<{}> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);

  const query = useQueryClient();

  const onSuccess = () => {
    setIsOpen(false);
    query.invalidateQueries(EVENT_ADMIN_LIST_MODULE_NAME);
    formik.resetForm();
  };

  const action = useMutation(
    () => {
      return createEvent(convert(formik.values));
    },
    {
      onSuccess,
    },
  );

  const config = {
    [FORM_VALUE_ENUM.CATEGORY]: [],
    [FORM_VALUE_ENUM.DESCRIPTION]: [required, maxLength(1000)],
    [FORM_VALUE_ENUM.IMG_URL]: [url, required],
    [FORM_VALUE_ENUM.LINK]: [required, url],
    [FORM_VALUE_ENUM.ORDER]: [required],
    [FORM_VALUE_ENUM.TAG]: [],
    [FORM_VALUE_ENUM.TITLE]: [required],
    [FORM_VALUE_ENUM.UTM]: [required],
    [FORM_VALUE_ENUM.STATUS]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: '',
    [FORM_VALUE_ENUM.DESCRIPTION]: '',
    [FORM_VALUE_ENUM.IMG_URL]: '',
    [FORM_VALUE_ENUM.LINK]: '',
    [FORM_VALUE_ENUM.ORDER]: '',
    [FORM_VALUE_ENUM.TAG]: '',
    [FORM_VALUE_ENUM.TITLE]: '',
    [FORM_VALUE_ENUM.UTM]: '',
    [FORM_VALUE_ENUM.STATUS]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {
      return action.mutate();
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }
    if (action.isLoading) {
      return true;
    }
  };
  console.log('isSubmitDisabled', isSubmitDisabled());

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, Array.isArray(e) ? e : [e]);
  };

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  return (
    <Component
      handleButtonClick={handleButtonClick}
      isOpen={isOpen}
      setFieldValue={setFieldValue}
      isFieldError={isFieldError}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      formik={formik}
    />
  );
};
