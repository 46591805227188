import React, { useEffect, useMemo, useState } from 'react';

import { Component } from './component';
import { EARNING_LIST_MODULE_NAME } from '../earning-list';
import { useQuery, useQueryClient } from 'react-query';
import { EARNING_USER_LIST_MODULE_NAME } from '../earning-create/constant';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { convertUser, convertEvent } from '../earning-create/convert';
import { ACTION_RESPONSE_INTER } from '../user-list/constant';
import { ACTION_RESPONSE_INTER as ACTION_RESPONSE_EVENT_INTER } from '../event-list/constant';
import { action as eventAction } from '../event-list/action';
import { getUserListShort } from '../../data/user/action';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';

export const Container: React.FC<{
  setPayload: React.Dispatch<
    React.SetStateAction<
      | {
          status: string;
          eventId?: string;
          userId?: string | number;
          startDate?: string;
          endDate?: string;
        }
      | undefined
    >
  >;
}> = ({ setPayload }) => {
  const [activeTab, setActiveTab] = useState('All');
  const [userId, setUserId] = useState<string | undefined>();
  const [eventId, setEventId] = useState<string | undefined>();
  const [date, setDate] = useState<[string, string] | []>([]);
  const [selectUserValue, setSelectUsertValue] = useState('');
  const [selectEventValue, setSelectEventValue] = useState('');

  const preFetchUser = useQuery(USER_LIST_SHORT_MODULE_NAME, () =>
    getUserListShort({ search: selectUserValue }),
  );
  const preFetchEvent = useQuery(EVENT_LIST_MODULE_NAME, () =>
    eventAction({ search: selectEventValue }),
  );

  const handleSelectUserChange = (newValue: any, _actionMeta: any) => {
    setSelectUsertValue(newValue);
  };

  const handleSelectEventChange = (newValue: any, _actionMeta: any) => {
    setSelectEventValue(newValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchUser.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectUserValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchEvent.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectEventValue]);

  const queryClient = useQueryClient();
  const getData = useMemo(() => {
    const data = preFetchUser.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      const convertData = convertUser(data);

      return { ...convertData, isEmpty: data?.list?.length < 1 };
    }

    return undefined;
  }, [preFetchUser.data]);

  const getDataEvent = useMemo(() => {
    const data = preFetchEvent.data as unknown as ACTION_RESPONSE_EVENT_INTER;

    if (data) {
      const convertData = convertEvent(data);

      return { ...convertData, isEmpty: data?.list?.length < 1 };
    }

    return undefined;
  }, [preFetchEvent.data]);

  const eventOptionList = getDataEvent?.list;
  const userOptionList = getData?.list;

  const invalidateCache = async () => {
    await queryClient.invalidateQueries([EARNING_LIST_MODULE_NAME]);
  };

  useEffect(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      status: activeTab,
      userId: userId,
      eventId: eventId,
      startDate: date[0],
      endDate: date[1],
    }));
    invalidateCache();
  }, [activeTab, userId, eventId, date]);

  return (
    <Component
      handleSelectUserChange={handleSelectUserChange}
      handleSelectEventChange={handleSelectEventChange}
      eventList={eventOptionList}
      userList={userOptionList}
      setUserId={setUserId}
      setEventId={setEventId}
      setDate={setDate}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};
