import { Dispatch } from '../../lib/store';
import { ACTION_TYPE } from './constant';
import { preloadAuthData, updateAuthData } from '../auth/action';
import { preloadThemeData } from '../theme/action';

const getStore = () => {
  return localStorage;
};

export const appPreloadAllLocalStateData = () => async (dispatch: Dispatch) => {
  dispatch({
    type: ACTION_TYPE.PENDING,
  });

  getStore();

  await dispatch(preloadAuthData());

  await dispatch(updateAuthData());

  await dispatch(preloadThemeData());

  dispatch({
    type: ACTION_TYPE.SUCCESS,
  });
};

export const appSaveLocalStateData = async (name: string, data: any) => {
  const localStore = getStore();

  localStore.setItem(name, JSON.stringify(data));
};

export const appClearLocalStateData = async (name: string) => {
  const localStore = getStore();

  localStore.removeItem(name);
};

export const appLoadLocalStateData = async (name: string) => {
  const localStore = getStore();
  const raw = localStore.getItem(name) || '';

  try {
    return JSON.parse(raw);
  } catch (error) {
    console.error(`Failed to parse JSON for ${name}:`, error);
    return null;
  }
};
