import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FormikValues } from 'formik';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { i18n } from '../../lib/lang';
import { FORM_VALUE_ENUM } from './constant';

export const Component: React.FC<{
  formik: FormikValues;
  getFieldError: Function;
  isSubmitDisabled: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSubmit?: any;
  getFieldValue: Function;
  isFieldError: Function;
  setFieldValue: Function;
}> = ({
  isError,
  errorMessage,
  isSuccess,
  isLoading,
  isSubmitDisabled,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
}) => {
  return (
    <>
      <Container>
        <FiledContainer>
          <FieldTextElem
            title="WITHDRAW.CREATE.ADDRESS_TITLE"
            placeholder="WITHDRAW.CREATE.ADDRESS"
            name={FORM_VALUE_ENUM.ADDRESS}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.ADDRESS)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.ADDRESS)}
            error={isFieldError(FORM_VALUE_ENUM.ADDRESS)}
            type="text"
          />
        </FiledContainer>
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="primary"
          tid="WITHDRAW.CREATE.BUTTON"
          onClick={formik.handleSubmit}
        />
      </Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </>
  );
};

const FiledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(7)};
`;
