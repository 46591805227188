import React from 'react';

import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import { ACTION_ERROR_INTER } from './constant';
import { actionExchange } from './action';
import { USER_GIFT } from '../../data/user/constant';
import { GIFT_USER_LIST_MODULE_NAME } from '../gift-user-list';
import { USER_BALANCE_MODULE_NAME } from '../user-balance';
import { useHistory } from 'react-router';
import { CREATE_WITHDRAW_GIFT_PAGE_PATH } from '../../page/create-withdraw-gift';

export const Container: React.FC<{ id: USER_GIFT }> = ({ id }) => {
  const query = useQueryClient();
  const history = useHistory();

  const redirect = (path: string) => {
    history.push(path);
  };

  const onSuccess = () => {
    query.invalidateQueries(GIFT_USER_LIST_MODULE_NAME);
    query.invalidateQueries(USER_BALANCE_MODULE_NAME);
  };

  const action = useMutation(
    () => {
      return actionExchange(id);
    },
    {
      onSuccess,
    },
  );

  const handleClickAction = () => {
    if (id === USER_GIFT.MERCH) {
      return;
    }

    return action.mutate();
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const handleRedirect = () => {
    redirect(`${CREATE_WITHDRAW_GIFT_PAGE_PATH}/${id}`);
  };

  return (
    <Component
      isNotExchange={id === USER_GIFT.MERCH}
      handleRedirect={handleRedirect}
      handleClickAction={handleClickAction}
      errorMessage={getErrorMessage()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
    />
  );
};
