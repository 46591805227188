import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { SelectElem } from '../../../common/select';
import {
  WITHDRAW_ITEM_DATA_INTER,
  FORM_VALUE_ENUM,
  STATUS_OPTION_LIST,
  USER_WITHDRAW_STATUS,
  USER_WITHDRAW_TYPE,
} from '../constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { WithdrawItemUpdateContainer } from '../../withdraw-item-update';
import { COLOR_ENUM } from '../../../theme/color';
import { CopyElem } from '../../../common/copy';
import { WITHDRAW_STATUS } from '../../withdraw-item-update/constant';
import IconLink from '../../../asset/svg/button/link-grey.svg';
import { GIFT_DATA } from '../../gift-user-list/constant';

export const CardWithdraw: React.FC<{
  data: WITHDRAW_ITEM_DATA_INTER;
  isUpdateDisabled?: boolean;
  isOpen: boolean;
  onClick: () => void;
  hanldeCloseModal: () => void;
}> = ({ data, isUpdateDisabled, isOpen, onClick, hanldeCloseModal }) => {
  const [formData, setFormData] = useState({
    status: data.status,
  });

  const isDisabled =
    isUpdateDisabled ||
    data.status.value === WITHDRAW_STATUS.COMPLETE ||
    data.status.value === WITHDRAW_STATUS.CANCEL;

  const id = data?.user?.telegramId;
  const userName = data?.user.userName;

  const handleChangeSelect = (name: string, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div>
      <Container className="user_container" isOpen={isOpen} onClick={onClick}>
        <UserContainer>
          <InfoContainer>
            <TextElem
              size="semiSmall"
              color="white"
              type="semi-bold"
              tid={`ID ${id || ''}`}
            />
            <TextElem
              color="textSecondary"
              size="small"
              type="medium"
              tid={userName}
            />
          </InfoContainer>
        </UserContainer>

        <BalanceContainer>
          {data.type === USER_WITHDRAW_TYPE.MONEY && (
            <TextElem
              color="white"
              size="semiSmall"
              type="semi-bold"
              oneLine
              tid={`$ ${data.amount}`}
            />
          )}
          {data.type === USER_WITHDRAW_TYPE.GIFT && (
            <TextElem
              color="white"
              size="semiSmall"
              type="semi-bold"
              oneLine
              tid={`${GIFT_DATA[data.gift].title}`}
            />
          )}

          <TextElem
            color="textSecondary"
            size="verySmall"
            type="semi-bold"
            tid="EARNING.LIST.DATE"
            tvalue={{ date: data.createDate }}
          />
        </BalanceContainer>

        {!isOpen && (
          <RoleContainer>
            <Chip status={data.status.value as USER_WITHDRAW_STATUS}>
              <TextElem
                color="white"
                size="small"
                type="medium"
                tid={data.status.label}
              />
            </Chip>
          </RoleContainer>
        )}
        {isOpen && (
          <RoleButtonContainer>
            <WithdrawItemUpdateContainer
              hanldeCloseModal={hanldeCloseModal}
              disabled={isDisabled}
              payload={formData}
              id={data.id}
            />
          </RoleButtonContainer>
        )}
      </Container>
      <>
        {isOpen && (
          <ExpandedContent>
            <CopyElem
              value={
                data?.address ? String(data?.address) : String(data?.wallet)
              }
            >
              <ContainerClickable>
                <ChipContainerClickable>
                  {data.type === USER_WITHDRAW_TYPE.GIFT && (
                    <TextElem
                      color="textSecondary"
                      size="small"
                      type="medium"
                      tid="WITHDRAW.LIST.ADDRESS"
                    />
                  )}

                  {data.type === USER_WITHDRAW_TYPE.MONEY && (
                    <>
                      <TextElem
                        color="textSecondary"
                        size="small"
                        type="medium"
                        tid="WITHDRAW.LIST.WALLET"
                      />
                    </>
                  )}

                  <TextElem
                    color="textThird"
                    size="small"
                    type="medium"
                    tid={
                      data?.address
                        ? String(data?.address)
                        : String(data.wallet)
                    }
                  />
                </ChipContainerClickable>
                <img src={IconLink} alt="copy" />
              </ContainerClickable>
            </CopyElem>
            <SelectElem
              name={FORM_VALUE_ENUM.STATUS}
              isDisabled={data.status.value === USER_WITHDRAW_STATUS.CANCEL}
              value={formData.status}
              onChange={handleChangeSelect}
              options={STATUS_OPTION_LIST}
              placeholder="EARNING.CREATE.STATUS"
            />
          </ExpandedContent>
        )}
      </>
    </div>
  );
};

const ChipContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
`;

const ChipContainerClickable = styled(ChipContainer)`
  padding: unset !important;
`;

const ContainerClickable = styled.div`
  padding: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
  width: 100%;

  display: flex;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Chip = styled.div<{ status: USER_WITHDRAW_STATUS }>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(2)};

  ${({ status }) => {
    if (status === USER_WITHDRAW_STATUS.PENDING) {
      return css`
        background-color: rgba(69, 172, 229, 0.3);

        span {
          color: #45ace5;
        }
      `;
    }

    if (status === USER_WITHDRAW_STATUS.COMPLETE) {
      return css`
        background-color: rgba(54, 178, 134, 0.3);

        span {
          color: #36b286;
        }
      `;
    }

    if (status === USER_WITHDRAW_STATUS.CANCEL) {
      return css`
        background-color: rgba(242, 72, 72, 0.3);

        span {
          color: #f24848;
        }
      `;
    }

    if (status === USER_WITHDRAW_STATUS.FOR_PAYMENT) {
      return css`
        background-color: rgba(209, 121, 38, 0.3);

        span {
          color: #d17926;
        }
      `;
    }
  }}
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: ${Spacing(1)};
`;

const ExpandedContent = styled.div`
  grid-column: span 3;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: ${Spacing(4)};
  align-items: center;
  transition: height 0.3s ease;
  margin-bottom: 12px;
`;

const RoleContainer = styled.div`
  display: grid;
  justify-self: end;
  min-width: 82px;
  text-align: end;
  align-self: center;
`;

const RoleButtonContainer = styled.div`
  display: grid;
  justify-self: end;

  position: absolute;
  right: 8px;
  max-width: 95px;
  top: 50%;
  transform: translateY(-50%);
`;

const UserContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: ${Spacing(1)};
`;

const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px 115px;
  padding: ${Spacing(5)} 0;
  align-items: center;

  position: relative;

  text-align: end;
  cursor: pointer;
  justify-content: center;
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]},
    height 0.3s ease;

  &:hover {
    > *:not(${RoleButtonContainer}) {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
    }
  }
`;
