import { i18n } from '../../lib/lang';
import { WITHDRAW_STATUS } from '../withdraw-item-update/constant';
import { USER_WITHDRAW_TYPE } from '../withdraw-list/constant';

export const MODULE_NAME = 'WITHDRAW_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const tabList = [
  { tid: 'WITHDRAW.TAB.ALL', value: 'All' },
  { tid: 'WITHDRAW.TAB.PENDING', value: WITHDRAW_STATUS.PENDING },
  { tid: 'WITHDRAW.TAB.COMPLETE', value: WITHDRAW_STATUS.COMPLETE },
  { tid: 'WITHDRAW.TAB.CANCEL', value: WITHDRAW_STATUS.CANCEL },
  { tid: 'WITHDRAW.TAB.FOR_PAYMENT', value: WITHDRAW_STATUS.FOR_PAYMENT },
];

const typeView = {
  [USER_WITHDRAW_TYPE.MONEY]: i18n.t('WITHDRAW.FILTER.MONEY'),
  [USER_WITHDRAW_TYPE.GIFT]: i18n.t('WITHDRAW.FILTER.GIFT'),
};

export const typeList = [
  {
    label: typeView[USER_WITHDRAW_TYPE.MONEY],
    value: USER_WITHDRAW_TYPE.MONEY,
  },
  {
    label: typeView[USER_WITHDRAW_TYPE.GIFT],
    value: USER_WITHDRAW_TYPE.GIFT,
  },
];

export enum FORM_VALUE_ENUM {
  USER = 'user',
  TYPE = 'type',
  DATE = 'date',
  STATUS = 'status',
}
