import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';

export const action = (payload: FORM_VALUE_INTER) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL,
    data: payload,
  });
};

export const actionPreFetch = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};
