import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const getUserList = () => {
  return HttpRequest({
    method: API.LIST.TYPE,
    url: API.LIST.URL,
  });
};

export const getUserListShort = async ({ search }: any): Promise<any> => {
  let params = '?';

  if (search) {
    params += `name=${search}`;
  }

  return HttpRequest({
    method: API.LIST_SHORT.TYPE,
    url: API.LIST_SHORT.URL(params),
  });
};
