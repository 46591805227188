import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { DATA_INTER } from './constant';
import { i18n } from '../../lib/lang';
import { Spacing } from '../../theme';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { SkeletonItem } from '../../common/skeleton-item';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  return (
    <>
      {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      <Double>
        <ChipContainer>
          <TextElemStyled
            color="white"
            size="verySmall"
            type="medium"
            tid="ANALITIC.USER.COUNT"
          />
          {isLoading && <SkeletonItem />}
          {isSuccess && (
            <TextElemStyled
              color="white"
              size="title"
              type="medium"
              tid={data?.total ? String(data?.total) : ''}
            />
          )}
        </ChipContainer>
        <ChipContainer>
          <TextElemStyled
            color="white"
            size="verySmall"
            type="medium"
            tid="ANALITIC.USER.ALL_RECEIPTS"
          />
          {isLoading && <SkeletonItem />}
          {isSuccess && (
            <TextElemStyled
              color="white"
              size="title"
              type="bold"
              tid={`$ ${data?.allReceipt ? String(data?.allReceipt) : ''}`}
            />
          )}
        </ChipContainer>
      </Double>
    </>
  );
};

const TextElemStyled = styled(TextElem)`
  line-height: 1em;
`;

const ChipContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: ${Spacing(4)};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;

  border: solid 1px rgba(229, 180, 95, 0.15);
`;

const Double = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  width: 100%;
`;
