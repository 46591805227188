import React from 'react';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import { updateData } from './action';
import { WITHDRAW_LIST_MODULE_NAME } from '../withdraw-list';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';
export const Container: React.FC<{
  id?: string;
  payload?: any;
  disabled?: boolean;
  hanldeCloseModal: () => void;
}> = ({ id, payload, disabled, hanldeCloseModal }) => {
  const query = useQueryClient();

  const onSuccess = () => {
    query.invalidateQueries(WITHDRAW_LIST_MODULE_NAME);
    query.invalidateQueries(USER_LIST_SHORT_MODULE_NAME);

    hanldeCloseModal();
  };

  const action = useMutation(() => updateData({ id: id, payload }), {
    onSuccess,
  });

  const handleUpdate = () => {
    action.mutate();
  };

  const isSubmitDisabled = () => {
    if (disabled) {
      return true;
    }

    if (action.isError) {
      return action.isError;
    }

    if (action.isLoading) {
      return action.isLoading;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isSuccess = () => {
    return action.isSuccess;
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  return (
    <Component
      isLoading={isLoading()}
      handleUpdate={handleUpdate}
      isSubmitDisabled={isSubmitDisabled}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
