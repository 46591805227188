import { components } from 'react-select';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import userIcon from '../../asset/svg/common/user.svg';

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <OptionContainer>
        <TextElemStyled
          type="medium"
          size="semiSmall"
          color="white"
          tid={props.data.userName}
        />
        <TextElem
          tid={`${String(props.data.level)} Lvl`}
          type="regular"
          size="small"
          color="white"
        />
        <OptionUserContainer>
          <TextElem
            tid={String(props.data.referalUser)}
            type="medium"
            size="small"
            color="textSecondary"
          />
          <UserImg src={userIcon} />
        </OptionUserContainer>
      </OptionContainer>
    </components.Option>
  );
};

export const customUserOption = {
  Option: CustomOption,
};

const OptionUserContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: end;
`;

const UserImg = styled.img`
  width: 13px;
  height: 13px;
`;

const TextElemStyled = styled(TextElem)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 65px 32px 34px;
  align-items: center;
  grid-gap: ${Spacing(1)};
  justify-content: space-between;
`;
