export enum LANG {
  UA = 'UA',
  RU = 'RU',
  PL = 'PL',
}

export const LANG_DEFAULT = LANG.RU;

export const LANG_SUPPORTED = [LANG.RU];

export const COOKIE_LANG = 'COOKIE_LANG';
