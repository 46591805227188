import React from 'react';

import { Component } from './component';

export const Container: React.FC<{
  search: string;
  setSearch: Function;
}> = ({ search, setSearch }) => {
  return <Component search={search} setSearch={setSearch} />;
};
