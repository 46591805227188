import React from 'react';
import { RouteComponentProps } from 'react-router';
import { AdminAnalyticContainer } from '../../epic/admin-analytic';
import { AdminNoticeCreateContainer } from '../../epic/admin-notice-create';
import styled from 'styled-components';
import { Spacing } from '../../theme';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageItemContainer>
      <AdminAnalyticContainer />
      <AdminNoticeCreateContainer />
    </PageItemContainer>
  );
};

const PageItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  width: 100%;
`;
