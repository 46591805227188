import React from 'react';
import { useQuery } from 'react-query';

import { ACTION_ERROR_INTER, DATA_INTER } from './constant';
import { USER_BALANCE_ANALYTIC_MODULE_NAME } from '../user-balance-analytic';
import { Component } from './component';

export const Container: React.FC<{}> = ({}) => {
  const preFetch = useQuery(USER_BALANCE_ANALYTIC_MODULE_NAME);

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess && !preFetch.isFetching) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as DATA_INTER;

    if (data) {
      return { ...data };
    }
  };

  return (
    <Component
      data={getData()}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
    />
  );
};
