import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { Spacing } from '../../theme';
import { SelectElem } from '../../common/select';
import { ButtonElem } from '../../common/button';
import { COLOR_ENUM } from '../../theme/color';
import { FieldTextElem } from '../../common/field-text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { i18n } from '../../lib/lang';
import {
  CATEGORY_LIST,
  FORM_VALUE_ENUM,
  STATUS_LIST,
  TAG_LIST,
} from './constant';

export const Component: React.FC<{
  formik: FormikValues;
  getFieldError: Function;
  isSubmitDisabled: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  getFieldValue: Function;
  isFieldError: Function;
  setFieldValue: Function;
  handleButtonClick: Function;
  isOpen: boolean;
}> = ({
  isError,
  errorMessage,
  isSuccess,
  isLoading,
  isSubmitDisabled,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  setFieldValue,
  handleButtonClick,
  isOpen,
}) => {
  return (
    <>
      <Container>
        {!isOpen && (
          <ButtonElem
            type="submit"
            tid="EVENT.CREATE.CREATE_NAME"
            onClick={handleButtonClick}
          />
        )}
        {isOpen && (
          <ExpandContainer>
            <SaveContainer>
              <FieldTextElem
                placeholder="EVENT.CREATE.IMG_URL"
                name={FORM_VALUE_ENUM.IMG_URL}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.IMG_URL)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.IMG_URL)}
                error={isFieldError(FORM_VALUE_ENUM.IMG_URL)}
              />
              <ButtonElem
                onClick={formik.handleSubmit}
                disabled={isSubmitDisabled()}
                type="submit"
                tid="EVENT.CREATE.SAVE"
              />
            </SaveContainer>
            <FieldTextElem
              placeholder="EVENT.CREATE.TITLE"
              name={FORM_VALUE_ENUM.TITLE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.TITLE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.TITLE)}
              error={isFieldError(FORM_VALUE_ENUM.TITLE)}
            />
            <DoubleContainer>
              <FieldTextElem
                placeholder="EVENT.CREATE.UTM"
                name={FORM_VALUE_ENUM.UTM}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.UTM)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.UTM)}
                error={isFieldError(FORM_VALUE_ENUM.UTM)}
              />
              <FieldTextElem
                placeholder="EVENT.CREATE.LINK"
                name={FORM_VALUE_ENUM.LINK}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.LINK)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.LINK)}
                error={isFieldError(FORM_VALUE_ENUM.LINK)}
              />
            </DoubleContainer>
            <DoubleContainer>
              <SelectElem
                placeholder="EVENT.CREATE.CATEGORY"
                options={CATEGORY_LIST}
                name={FORM_VALUE_ENUM.CATEGORY}
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
                error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
              />
              <SelectElem
                placeholder="EVENT.CREATE.TAG"
                options={TAG_LIST}
                name={FORM_VALUE_ENUM.TAG}
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.TAG)}
                error={isFieldError(FORM_VALUE_ENUM.TAG)}
              />
            </DoubleContainer>
            <DoubleContainer>
              <SelectElem
                placeholder="EVENT.CREATE.STATUS"
                options={STATUS_LIST}
                name={FORM_VALUE_ENUM.STATUS}
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.STATUS)}
                error={isFieldError(FORM_VALUE_ENUM.STATUS)}
              />
              <FieldTextElem
                placeholder="EVENT.CREATE.ORDER"
                name={FORM_VALUE_ENUM.ORDER}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.ORDER)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.ORDER)}
                error={isFieldError(FORM_VALUE_ENUM.ORDER)}
                type="number"
              />
            </DoubleContainer>

            <FieldTextAreaElem
              placeholder="EVENT.CREATE.DESCRIPTION"
              name={FORM_VALUE_ENUM.DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            />
          </ExpandContainer>
        )}
      </Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </>
  );
};

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const SaveContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const ExpandContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  padding: ${Spacing(4)} 0 0 0;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(3)};
`;
