import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA_INTER, WITHDRAW_ITEM_DATA_INTER } from './constant';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { CardWithdraw } from './frame/card-withdraw';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { EmptyListElem } from '../../common/empty-list-elem';
import { TextElem } from '../../common/text';
import { PaginationElem } from '../../common/pagination';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  pageSize: number;
  page: number;
  onChangePage: (arg: number) => void;
  pageCount: number;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  pageSize,
  page,
  onChangePage,
  pageCount,
}) => {
  return (
    <GridElemStyled spacing={4}>
      <TextElem
        tid="WITHDRAW.LIST.USER.TITLE"
        type="semi-bold"
        color="white"
        size="semiSmall"
      />
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      <GridElem spacing={5}>
        {data?.isEmpty && !isLoading && (
          <EmptyListElem
            titleTid="WITHDRAW.LIST.IS_EMPTY_TITLE"
            descriptionTid="WITHDRAW.LIST.IS_EMPTY_DESCRIPTION"
          />
        )}
        {isSuccess &&
          data?.list &&
          !data.isEmpty &&
          data?.list.map((item: WITHDRAW_ITEM_DATA_INTER) => (
            <>
              <CardWithdraw data={item} />
            </>
          ))}
      </GridElem>
      {isLoading && (
        <>
          <Divider />
          <SkeletonDataStyled />
        </>
      )}
      {!data?.isEmpty && (
        <PaginationStyled
          defaultPageSize={pageSize}
          current={page}
          onChange={onChangePage}
          total={pageCount}
        />
      )}
    </GridElemStyled>
  );
};

const GridElemStyled = styled(GridElem)`
  margin-bottom: ${Spacing(22)};
`;

const PaginationStyled = styled(PaginationElem)`
  position: absolute;
  bottom: ${Spacing(33)};
  left: 50%;
  transform: translateX(-50%);
`;

const SkeletonDataStyled = styled(SkeletonDataElem)`
  margin-top: ${Spacing(6)};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  height: 1px;
  width: 100%;
`;
