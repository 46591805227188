import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';

import { TextElem } from '../../common/text';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

import { FieldTextAreaElem } from '../../common/field-text-area';
import { ModalConfirmElem } from '../../common/modal-confirm';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSubmit?: any;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  onSubmit,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="ADDITIONAL.NEWSLETTER.SUCCESS" />
      )}
      <Container>
        <TextElem
          color="white"
          type="semi-bold"
          size="semiSmall"
          tid="ADDITIONAL.NEWSLETTER.TITLE"
        />
        <ContentContainer>
          <FieldTextAreaElem
            name={FORM_VALUE_ENUM.MESSAGE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
            placeholder="ADDITIONAL.NEWSLETTER.MESSAGE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
            error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
          />
          <FieldTextElem
            name={FORM_VALUE_ENUM.PHOTO_LINK}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.PHOTO_LINK)}
            placeholder="ADDITIONAL.NEWSLETTER.PHOTO"
            errorMessage={getFieldError(FORM_VALUE_ENUM.PHOTO_LINK)}
            error={isFieldError(FORM_VALUE_ENUM.PHOTO_LINK)}
          />
          <FieldTextElem
            name={FORM_VALUE_ENUM.BUTTON_LINK}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.BUTTON_LINK)}
            placeholder="ADDITIONAL.NEWSLETTER.BUTTON"
            errorMessage={getFieldError(FORM_VALUE_ENUM.BUTTON_LINK)}
            error={isFieldError(FORM_VALUE_ENUM.BUTTON_LINK)}
          />
          <FieldTextElem
            name={FORM_VALUE_ENUM.BUTTON_NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.BUTTON_NAME)}
            placeholder="ADDITIONAL.NEWSLETTER.BUTTON_TITLE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.BUTTON_NAME)}
            error={isFieldError(FORM_VALUE_ENUM.BUTTON_NAME)}
          />
        </ContentContainer>
        <ContentContainer>
          <ModalConfirmElem
            action={() => onSubmit('all')}
            titleTid={'titleTid'}
            titleValue={{ value: 'titleValue' }}
            type="confirm"
          >
            <ButtonElem
              disabled={isSubmitDisabled()}
              type="primary"
              tid="ADDITIONAL.NEWSLETTER.BUTTON_TEXT.ALL"
            />
          </ModalConfirmElem>
          <ModalConfirmElem
            action={() => onSubmit('test')}
            titleTid={'titleTid'}
            titleValue={{ value: 'titleValue' }}
            type="confirm"
          >
            <ButtonElem
              disabled={isSubmitDisabled()}
              type="submit"
              tid="ADDITIONAL.NEWSLETTER.BUTTON_TEXT.TEST"
            />
          </ModalConfirmElem>
        </ContentContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(5)};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;
