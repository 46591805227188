import React, { useState } from 'react';
import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { i18n } from '../../../lib/lang';
import { ButtonElem } from '../../../common/button';
import { ModalFullElem } from '../../../common/modal-full';

export const CardEducation: React.FC<{
  img: string;
  title: string;
  description: string;
  video: string;
  link: any[];
}> = ({ img, title, description, video, link }) => {
  const [isOpen, setIsOpen] = useState(false);

  const imgTid = i18n.t(img);
  const titleTid = i18n.t(title);
  const descriptionTid = i18n.t(description);
  const videoTid = i18n.t(video);

  const handleOpen = (url: string) => {
    const href = i18n.t(url);
    window.Telegram.WebApp.openLink(href);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Container onClick={handleClick}>
        <ImgStyled src={imgTid} alt={titleTid} />
        <TitleContainer>
          <TextElem
            color="white"
            type="semi-bold"
            size="semiSmall"
            tid={titleTid}
          />
          <TextElem
            color="textSecondary"
            size="small"
            type="medium"
            tid={descriptionTid}
          />
        </TitleContainer>
      </Container>
      <ModalFullElem onClose={handleClose} isOpen={isOpen}>
        <TextElem color="white" type="semi-bold" size="main" tid={titleTid} />

        <Iframe
          src={videoTid}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <TextElem
          color="textSecondary"
          type="regular"
          size="small"
          tid={descriptionTid}
        />

        <ButtonContainer>
          {link.map((e) => (
            <LinkContainer>
              <LinkTitle
                type="medium"
                size="small"
                color="default"
                tid={e.title}
              />
              <ButtonElemStyled
                onClick={() => handleOpen(e.url)}
                type="submit"
                tid={e.buttonTitle}
              />
            </LinkContainer>
          ))}
        </ButtonContainer>
      </ModalFullElem>
    </>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  padding: 12px 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LinkTitle = styled(TextElem)`
  letter-spacing: 1%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  width: 100%;
  margin-top: ${Spacing(6)};
  padding-bottom: ${Spacing(6)};
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  max-height: 233px;
  min-height: auto;
  margin: ${Spacing(3)} 0;
  border: none;
  margin-bottom: ${Spacing(2)};
  object-fit: contain;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
  align-self: start;
`;

const ImgStyled = styled.img`
  height: ${Spacing(40)};
  width: 100%;
  object-fit: cover;
  align-self: start;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-items: center;
  cursor: pointer;
  gap: ${Spacing(3)};

  transition: height 0.3s ease,
    opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
