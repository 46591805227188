export const MODULE_NAME = 'WITHDRAW_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const API = {
  TYPE: 'POST',
  URL: 'withdraw/create-withdraw',
};

export enum FORM_VALUE_ENUM {
  WALLET = 'wallet',
  AMOUNT = 'amount',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.WALLET]: string;
  [FORM_VALUE_ENUM.AMOUNT]: number;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export const tabList = [
  { tid: 'EARNING.TAB.ALL', value: 1 },
  { tid: 'EARNING.TAB.PENDING', value: 2 },
  { tid: 'EARNING.TAB.COMPLETE', value: 3 },
  { tid: 'EARNING.TAB.CANCEL', value: 4 },
];
