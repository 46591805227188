import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ search, pageParam }: any) => {
  let params = '?';

  if (search) {
    params += `name=${search}`;
  }

  if (pageParam) {
    params += `&skip${pageParam}`;
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL,
  });
};
