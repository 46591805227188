import React from 'react';
import { Component } from './component';
import { getTelegramUserData } from '../../lib/util/telegramData';
import { useQuery } from 'react-query';
import { USER_BALANCE_ANALYTIC_MODULE_NAME } from '../user-balance-analytic';
import { actionPhoto } from './action';
import { MODULE_NAME_PHOTO } from './constant';
import { action } from '../user-balance-analytic/action';

export const Container: React.FC<{}> = ({}) => {
  const telegramUserData = getTelegramUserData();
  const preFetch = useQuery(USER_BALANCE_ANALYTIC_MODULE_NAME);

  const { data: photo, isLoading: photoIsLoading } = useQuery(
    [MODULE_NAME_PHOTO],
    actionPhoto,
  );

  const base64Image = `data:image/jpeg;base64,${photo}`;

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const user = telegramUserData?.user || {};

  return (
    <Component
      photoIsLoading={photoIsLoading}
      photo={base64Image}
      data={user}
      isLoading={isLoading()}
      isError={false}
      isSuccess={true}
    />
  );
};
