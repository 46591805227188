import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { EventCreateContainer } from '../../epic/event-create';
import { EventAdminListContainer } from '../../epic/admin-event-list';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <Container>
      <EventCreateContainer />
      <EventAdminListContainer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  width: 100%;
  max-width: 400px;
`;
