import {
  USER_ITEM_LIST_DATA_RAW_INTER,
  USER_ITEM_LIST_DATA_INTER,
} from '../../data/user/constant';

export const MODULE_NAME = 'USER_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `/user/list/${params}`,
};

export interface ACTION_RESPONSE_INTER extends USER_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends USER_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export enum USER_LVL {
  ZERO = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
}
