import { USER_LVL } from '../../epic/user-list/constant';
import { i18n } from '../../lib/lang';
import { USER_GIFT } from '../user/constant';

export const USER_LVL_OPTION_LIST = [
  {
    value: USER_LVL.ZERO,
    label: i18n.t('USER.LVL.ZERO'),
  },
  {
    value: USER_LVL.FIRST,
    label: i18n.t('USER.LVL.FIRST'),
  },
  {
    value: USER_LVL.SECOND,
    label: i18n.t('USER.LVL.SECOND'),
  },
  {
    value: USER_LVL.THIRD,
    label: i18n.t('USER.LVL.THIRD'),
  },
  {
    value: USER_LVL.FOURTH,
    label: i18n.t('USER.LVL.FOURTH'),
  },
  {
    value: USER_LVL.FIFTH,
    label: i18n.t('USER.LVL.FIFTH'),
  },
];

export const LEVEL_PERSON_AMOUNT = [
  { level: USER_LVL.ZERO, amount: 0 },
  { level: USER_LVL.FIRST, amount: 10 },
  { level: USER_LVL.SECOND, amount: 20 },
  { level: USER_LVL.THIRD, amount: 30 },
  { level: USER_LVL.FOURTH, amount: 50 },
  { level: USER_LVL.FIFTH, amount: 100 },
];

export const LEVEL_GIFT = {
  [USER_LVL.ZERO]: null,
  [USER_LVL.FIRST]: USER_GIFT.MERCH,
  [USER_LVL.SECOND]: USER_GIFT.AIRPODS,
  [USER_LVL.THIRD]: USER_GIFT.WALLET,
  [USER_LVL.FOURTH]: USER_GIFT.APPLE_WATCH,
  [USER_LVL.FIFTH]: USER_GIFT.IPHONE_MACBOOK,
};
