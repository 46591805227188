import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { WithdrawFilterContainer } from '../../epic/withdraw-filter';
import { Spacing } from '../../theme';
import { WithdrawListContainer } from '../../epic/withdraw-list';

export const Page: React.FC<RouteComponentProps> = () => {
  const [payload, setPayload] = useState<{
    status: string;
    type?: string;
    userId?: string | number;
    startDate?: string;
    endDate?: string;
  }>();

  return (
    <Container>
      <WithdrawFilterContainer setPayload={setPayload} />
      <WithdrawListContainer payload={payload} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  width: 100%;
  max-width: 400px;
`;
