import React from 'react';
import styled, { css } from 'styled-components';

import Logo from '../../asset/svg/common/logo.svg';
import User from '../../asset/svg/common/user-octagon.svg';
import ArrowBackSvg from '../../asset/svg/button/arrow-back-outline.svg';

import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import { useHistory, useLocation } from 'react-router';
import { HEADER_TITLE } from './constant';
import { VALUE_OPACITY_DATA, VALUE_OPACITY_ENUM } from '../../theme/value';
import { ADMIN_PAGE, WITHOUT_TOOLBAR_PAGE } from '../toolbar-menu/constant';
import { ANALYTIC_PAGE_PATH } from '../../page/analytic';
import { USER_ADMIN_PAGE_PATH } from '../../page/admin-user';
import { LanguageSelectContainer } from '../language-select';

export const Component: React.FC<{ isAdmin: boolean; isEmail: boolean }> = ({
  isAdmin,
  isEmail,
}) => {
  const router = useLocation();
  const pathname = router.pathname;
  const titleKey =
    Object.keys(HEADER_TITLE).find((key) => pathname.startsWith(key)) || '';
  const title = HEADER_TITLE[titleKey];

  const history = useHistory();

  const goBack = () => {
    if (isEmail) {
      history.goBack();
    }
  };

  const isAdminPage = (path: string) => ADMIN_PAGE.includes(path);

  const isToolbarMenu = (path: string) => {
    return !WITHOUT_TOOLBAR_PAGE.some((p) => pathname.startsWith(p));
  };

  const actionPath = isAdminPage(pathname)
    ? ANALYTIC_PAGE_PATH
    : USER_ADMIN_PAGE_PATH;

  return (
    <Container>
      {!isToolbarMenu(pathname) && (
        <ImgButton onClick={goBack} alt="button" src={ArrowBackSvg} />
      )}
      {isToolbarMenu(pathname) && <LanguageSelectContainer />}

      <TitleContainer isRightMove={isToolbarMenu(pathname)}>
        <ImgStyled alt="logo" src={Logo} />
        <TextElem size="title" color="white" type="semi-bold" tid={title} />
      </TitleContainer>
      {isAdmin && isToolbarMenu(pathname) && (
        <RightContainer>
          <ImgButton
            onClick={() => history.push(actionPath)}
            alt="button"
            src={User}
          />
        </RightContainer>
      )}
    </Container>
  );
};

const ImgButton = styled.img`
  width: 24px;
  height: auto;
  display: flex;

  cursor: pointer;

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const ImgStyled = styled.img`
  width: 21px;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing(4)} 0;
`;

const TitleContainer = styled.div<{ isRightMove: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: ${Spacing(3)};

  ${({ isRightMove }) =>
    isRightMove &&
    css`
      position: relative;
      right: 23px;
    `}
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;
