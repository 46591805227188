import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

export interface FETCH_PARAM_INTER {
  pageParam?: number;
  search?: string;
  debounce?: number;
  pageSize?: number;
  status?: string;
  eventId?: string;
  userId?: string | number;
  startDate?: string;
  endDate?: string;
  type?: string;
}

export interface PAGINATED_DATA_INTER<T> {
  list: T[];
  total: number;
  count?: number;
  page: number;
  pageSize: number;
}

export const usePaginatedData = <T>(
  action: (params: FETCH_PARAM_INTER) => Promise<PAGINATED_DATA_INTER<T>>,
  moduleName: string,
  param?: FETCH_PARAM_INTER,
  onSuccess?: Function,
) => {
  const [debounceSearch, setDebounceSearch] = useState('');

  const preFetch = useInfiniteQuery({
    queryKey: [
      moduleName,
      debounceSearch,
      param?.startDate,
      param?.endDate,
      param?.status,
      param?.eventId,
      param?.userId,
      param?.pageParam,
      param?.type,
    ],
    queryFn: () =>
      action({ ...param, pageParam: param?.pageParam, search: debounceSearch }),
    getNextPageParam: (lastPage, allPages) => {
      const hasMorePages = lastPage?.list?.length > 0;

      return hasMorePages ? allPages?.length : undefined;
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebounceSearch(param?.search || '');
    }, param?.debounce || 0);

    return () => clearTimeout(delayDebounceFn);
  }, [param?.search, param?.debounce]);

  return preFetch;
};
