import ReactMarkdown from 'react-markdown';

import { i18n } from '../../lib/lang';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';

export const MarkDown = ({ tid, theme }: any) => {
  const components = {
    a: ({ href, children }: any) => (
      <LinkStyled href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </LinkStyled>
    ),
  };

  const markdownContent = i18n.t(tid);

  return (
    <div>
      <ReactMarkdownStyled components={components}>
        {markdownContent}
      </ReactMarkdownStyled>
    </div>
  );
};

const ReactMarkdownStyled = styled(ReactMarkdown)`
  span,
  li,
  p {
    margin: unset !important;
    font-size: 13px !important;
    line-height: 1.7em !important;
    max-width: 90%;
    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }
`;

const LinkStyled = styled.a`
  color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
`;
