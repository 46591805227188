import { convertDateToListTimezone } from '../../lib/lang/service';
import {
  WITHDRAW_ITEM_DATA_RAW_INTER,
  WITHDRAW_STATUS_VIEW,
} from '../withdraw-list/constant';
import { DATA_INTER } from './constant';

export const convert = (data: WITHDRAW_ITEM_DATA_RAW_INTER[]): DATA_INTER => {
  const list = data.map((e) => {
    const convert = {
      ...e,
      createDate: convertDateToListTimezone(e.createDate),
      status: {
        value: e.status,
        label: WITHDRAW_STATUS_VIEW[e.status],
      },
    };

    return convert;
  });

  return { list, isEmpty: list?.length < 1 };
};
