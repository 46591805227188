export const MODULE_NAME = 'ADMIN_NOTICE_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  MESSAGE = 'message',
  PHOTO_LINK = 'photoLink',
  BUTTON_LINK = 'buttonLink',
  BUTTON_NAME = 'buttonName',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.MESSAGE]: string;
  [FORM_VALUE_ENUM.PHOTO_LINK]: string;
  [FORM_VALUE_ENUM.BUTTON_LINK]: string;
  [FORM_VALUE_ENUM.BUTTON_NAME]: string;
}

export const API = {
  TYPE: 'POST',
  URL: (query: string) => `telegram/admin/notice?option=${query}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface MUTATION_PROPS {
  values: FORM_VALUE_INTER;
  query: string;
}
