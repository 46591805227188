import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { DATA_INTER } from './constant';
import { i18n } from '../../lib/lang';
import { Spacing } from '../../theme';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import giftSvg from '../../asset/svg/common/gift-user.svg';
import userSvg from '../../asset/svg/common/user-gold.svg';
import triangleSvg from '../../asset/svg/common/triangle.svg';
import { LEVEL_GIFT, LEVEL_PERSON_AMOUNT } from '../../data/level/constant';
import { USER_LVL } from '../user-list/constant';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  if (isLoading) {
    return <></>;
  }

  const level = data?.level ? data.level : 0;
  const referalUser = data?.referalUser ? data.referalUser : 0;

  const currentLevel = LEVEL_PERSON_AMOUNT.find((l) => l.level === level);
  const nextLevel = LEVEL_PERSON_AMOUNT.find((l) => l.level === level + 1);

  const isLastLevel = level === USER_LVL.FIFTH;

  let progress = 0;

  if (currentLevel && nextLevel) {
    progress = Math.min(
      ((referalUser - currentLevel.amount) /
        (nextLevel.amount - currentLevel.amount)) *
        100,
      100,
    );
  }

  const isCurrentLevelGift = currentLevel?.level
    ? !!LEVEL_GIFT[currentLevel.level]
    : null;

  const isNextLevelGift = nextLevel?.level
    ? !!LEVEL_GIFT[nextLevel.level]
    : null;

  return (
    <Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      <Row>
        <Column>
          <TextElem
            tid={`USER.GIFT.LEVEL.${currentLevel?.level}.TITLE`}
            color="white"
            type="semi-bold"
            size="small"
          />
          <TextElem
            tid={`USER.GIFT.LEVEL.${currentLevel?.level}.AMOUNT`}
            color="textSecondary"
            type="semi-bold"
            size="small"
          />
        </Column>
        {!isLastLevel && (
          <Column>
            <TextElem
              tid={`USER.GIFT.LEVEL.${nextLevel?.level}.TITLE`}
              color="white"
              type="semi-bold"
              size="small"
            />
            <TextElem
              tid={`USER.GIFT.LEVEL.${nextLevel?.level}.AMOUNT`}
              color="textSecondary"
              type="semi-bold"
              size="small"
            />
          </Column>
        )}
      </Row>
      <ProgressLineContainer>
        <ProgressContainer>
          <BackgroundLine />
          <StartProgress />
          <Dot size={9} />
          <ProgressBar progress={isLastLevel ? 92 : progress} />
          <AmountInfo>
            <TringleImg src={triangleSvg} />

            <TextElemStyled
              color="default"
              type="semi-bold"
              size="alert"
              tid="USER.GIFT.ALERT"
              tvalue={{
                current: referalUser,
                next: nextLevel?.amount ? nextLevel.amount : '100',
              }}
            />

            <ImgStyled src={userSvg} />
          </AmountInfo>
          <Dot size={15}></Dot>
        </ProgressContainer>

        {!isLastLevel && (
          <EndContainer>
            <BlackDot size={9} />
            <EndProgress />
          </EndContainer>
        )}
      </ProgressLineContainer>

      <Row>
        <Column>
          <TextElem
            tid={`USER.GIFT.LEVEL.${currentLevel?.level}.REF`}
            color="white"
            type="semi-bold"
            size="small"
          />
          {isCurrentLevelGift && (
            <GiftContainer>
              <img src={giftSvg} />

              <TextElem
                tid={`USER.GIFT.LEVEL.${currentLevel?.level}.GIFT`}
                color="textSecondary"
                type="semi-bold"
                size="small"
              />
            </GiftContainer>
          )}
        </Column>
        <Column>
          {!isLastLevel && (
            <TextElem
              tid={`USER.GIFT.LEVEL.${nextLevel?.level}.REF`}
              color="white"
              type="semi-bold"
              size="small"
            />
          )}
          {isNextLevelGift && !isLastLevel && (
            <GiftContainer>
              <img src={giftSvg} />
              <TextElem
                tid={`USER.GIFT.LEVEL.${nextLevel?.level}.GIFT`}
                color="textSecondary"
                type="semi-bold"
                size="small"
              />
            </GiftContainer>
          )}
        </Column>
      </Row>
    </Container>
  );
};

const TextElemStyled = styled(TextElem)`
  z-index: 2;
`;

const ImgStyled = styled.img`
  z-index: 2;
`;

const TringleImg = styled.img`
  width: 81px;
  height: auto;
  position: absolute;
  top: 3px;
  z-index: 2;
`;

const AmountInfo = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2px;
  width: max-content;
  padding: 13px ${Spacing(3)};
  top: -53px;
  left: 50%;
  transform: translateX(-50%);
`;

const GiftContainer = styled.div`
  display: flex;
  gap: ${Spacing(1)};
`;

const EndContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EndProgress = styled.div`
  width: 20px;
  background-color: #242629;
  height: 2px;
  border-radius: 2px;
`;

const StartProgress = styled.div`
  width: 20px;
  background: linear-gradient(90deg, #f4e187, #e5b45f);
`;

const BlackDot = styled.div<{ size: number }>`
  background-color: #242629;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: 3px solid #0b0b0b;
`;

const Dot = styled.div<{ size: number }>`
  background: linear-gradient(90deg, #f4e187, #e5b45f);
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: 3px solid #0b0b0b;
  position: relative;
`;

const ProgressContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const ProgressLineContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`;

const BackgroundLine = styled.div`
  background-color: #242629;
  width: 100%;
  position: absolute;
  height: 2px;
  border-radius: 2px;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => (progress > 100 ? 100 : progress)}%;
  background: linear-gradient(90deg, #f4e187, #e5b45f);
  height: 2px;
  border-radius: 2px;
  text-align: right;
  z-index: 2;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  position: relative;
  padding: ${Spacing(5)} ${Spacing(4)};

  border-bottom-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px;
  border-bottom-left-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DEFAULT
  ]}px;
  background: rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);
`;
