import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <TextElemStyled
      tid="HEADER.UNLOGINED"
      type="medium"
      size="title"
      color="textSecondary"
    />
  );
};

const TextElemStyled = styled(TextElem)`
  width: 100%;
  text-align: center;
  padding: ${Spacing(5)};
`;
