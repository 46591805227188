import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM, SKILL_LIST, SKILL_LIST_SHORT } from './constant';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { Spacing } from '../../theme';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { SelectElem } from '../../common/select';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSubmit?: any;
  onChangeSelect: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  onSubmit,
  onChangeSelect,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="USER.UPDATE.SUCCESS" />
      )}

      <Container>
        <FieldTextElem
          isRequired
          title="USER.UPDATE.EMAIL"
          name={FORM_VALUE_ENUM.EMAIL}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
          placeholder="USER.UPDATE.EMAIL"
          errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
          error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
        />
        <FieldTextElem
          isRequired
          title="USER.UPDATE.FULL_NAME"
          name={FORM_VALUE_ENUM.FULL_NAME}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.FULL_NAME)}
          placeholder="USER.UPDATE.FULL_NAME"
          errorMessage={getFieldError(FORM_VALUE_ENUM.FULL_NAME)}
          error={isFieldError(FORM_VALUE_ENUM.FULL_NAME)}
        />
        <FieldTextAreaElem
          isRequired
          title="USER.UPDATE.OCCUPATION"
          name={FORM_VALUE_ENUM.OCCUPATION}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.OCCUPATION)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.OCCUPATION)}
          error={isFieldError(FORM_VALUE_ENUM.OCCUPATION)}
        />
        <SelectElem
          isRequired
          title="USER.UPDATE.SKILL"
          isMulti
          options={SKILL_LIST_SHORT}
          name={FORM_VALUE_ENUM.SKILL}
          errorMessage={getFieldError(FORM_VALUE_ENUM.SKILL)}
          error={isFieldError(FORM_VALUE_ENUM.SKILL)}
          value={getFieldValue(FORM_VALUE_ENUM.SKILL)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />

        <FieldTextElem
          title="USER.UPDATE.PHONE"
          name={FORM_VALUE_ENUM.PHONE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
          placeholder="USER.UPDATE.PHONE"
          errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
          error={isFieldError(FORM_VALUE_ENUM.PHONE)}
          type="phone"
        />
        <FieldTextElem
          title="USER.UPDATE.DISCORD"
          name={FORM_VALUE_ENUM.DISCORD}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.DISCORD)}
          placeholder="USER.UPDATE.DISCORD"
          errorMessage={getFieldError(FORM_VALUE_ENUM.DISCORD)}
          error={isFieldError(FORM_VALUE_ENUM.DISCORD)}
        />
        <FieldTextElem
          title="USER.UPDATE.INSTAGRAM"
          name={FORM_VALUE_ENUM.INSTAGRAM}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.INSTAGRAM)}
          placeholder="USER.UPDATE.INSTAGRAM"
          errorMessage={getFieldError(FORM_VALUE_ENUM.INSTAGRAM)}
          error={isFieldError(FORM_VALUE_ENUM.INSTAGRAM)}
        />
        <FieldTextAreaElem
          isRequired
          title="USER.UPDATE.AUDIENCE"
          name={FORM_VALUE_ENUM.AUDIENCE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.AUDIENCE)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.AUDIENCE)}
          error={isFieldError(FORM_VALUE_ENUM.AUDIENCE)}
        />
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="primary"
          tid="USER.UPDATE.BUTTON"
          onClick={onSubmit}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  padding-bottom: ${Spacing(50)};
`;
