import { USER_ROLE } from '../../data/auth/constant';
import { USER_LVL } from '../user-list/constant';

export const MODULE_NAME = 'USER_ITEM_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  ROLE = 'role',
  LEVEL = 'level',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.ROLE]: USER_ROLE | undefined;
  [FORM_VALUE_ENUM.LEVEL]: USER_LVL | undefined;
}

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: (id?: string) => `user/admin/user/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
