import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useDispatch, useSelector } from './lib/store';
import { APP_MODULE_NAME, appPreloadAllLocalStateData } from './data/app';
import { isRequestSuccess } from './lib/store/service';
import { TextElem } from './common/text';
import { IndexPage } from './page/index';

export const App: React.FC = () => {
  const dispatch = useDispatch();

  const { state } = useSelector((s: any) => ({
    state: s[APP_MODULE_NAME],
  }));

  React.useEffect(() => {
    dispatch(appPreloadAllLocalStateData());
  }, []);

  const isSuccess = () => {
    return isRequestSuccess(state.request);
  };

  const success = isSuccess();

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <TextElem
          tid="ERROR.ERROR_BOUNDARY"
          type="medium"
          size="title"
          color="textSecondary"
        />
      )}
      onError={(error, info) => {
        alert(`Error caught by ErrorBoundary: ${error} ${info}`);
      }}
    >
      <React.Fragment>{success && <IndexPage />}</React.Fragment>
    </ErrorBoundary>
  );
};
