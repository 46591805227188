import { i18n } from '../../lib/lang';
import {
  EVENT_CATEGORY_VIEW,
  EVENT_CATEGORY,
  EVENT_STATUS,
  EVENT_STATUS_VIEW,
  EVENT_TAG,
  EVENT_TAG_VIEW,
} from '../event-list/constant';

export const MODULE_NAME = 'EVENT_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const EVENT_CREATE_MODULE_NAME = 'EVENT_CREATE_MODULE_NAME';

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const API = {
  TYPE: 'POST',
  URL: 'event/create',
};

export enum FORM_VALUE_ENUM {
  LINK = 'link',
  IMG_URL = 'imgUrl',
  TITLE = 'title',
  DESCRIPTION = 'description',
  TAG = 'tag',
  CATEGORY = 'category',
  ORDER = 'order',
  STATUS = 'status',
  UTM = 'utmParam',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface SELECT_INTER {
  value: number;
  label: string;
}

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.TITLE]: string;
  [FORM_VALUE_ENUM.LINK]: string;
  [FORM_VALUE_ENUM.IMG_URL]: string;
  [FORM_VALUE_ENUM.DESCRIPTION]: string;
  [FORM_VALUE_ENUM.TAG]: SELECT_INTER[];
  [FORM_VALUE_ENUM.CATEGORY]: SELECT_INTER[];
  [FORM_VALUE_ENUM.ORDER]: number;
  [FORM_VALUE_ENUM.STATUS]: SELECT_INTER[];
  [FORM_VALUE_ENUM.UTM]: string;
}

export const CATEGORY_LIST = [
  {
    label: i18n.t(EVENT_CATEGORY_VIEW[EVENT_CATEGORY.ONLINE]),
    value: EVENT_CATEGORY.ONLINE,
  },
  {
    label: i18n.t(EVENT_CATEGORY_VIEW[EVENT_CATEGORY.OFFLINE]),
    value: EVENT_CATEGORY.OFFLINE,
  },
];

export const TAG_LIST = [
  {
    label: i18n.t(EVENT_TAG_VIEW[EVENT_TAG.HOT]),
    value: EVENT_TAG.HOT,
  },
  {
    label: i18n.t(EVENT_TAG_VIEW[EVENT_TAG.NEW]),
    value: EVENT_TAG.NEW,
  },
];

export const STATUS_LIST = [
  {
    label: i18n.t(EVENT_STATUS_VIEW[EVENT_STATUS.ACTIVE]),
    value: EVENT_STATUS.ACTIVE,
  },
  {
    label: i18n.t(EVENT_STATUS_VIEW[EVENT_STATUS.DISABLED]),
    value: EVENT_STATUS.DISABLED,
  },
];
