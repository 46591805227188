import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import styled from 'styled-components';
import { UserBalanceContainer } from '../../epic/user-balance';
import { Spacing } from '../../theme';
import { WithdrawUserContainer } from '../../epic/withdraw-user-list';
import { GiftUserContainer } from '../../epic/gift-user-list';
import { ButtonElem } from '../../common/button';
import { CREATE_WITHDRAW_WALLET_PAGE_PATH } from '../create-withdraw-wallet';

export const Page: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  const redirect = (path: string) => {
    history.push(path);
  };

  return (
    <Layout>
      <UserBalanceContainer />
      <ButtonElem
        type="primary"
        tid="WITHDRAW.CREATE.BUTTON_BALANCE"
        onClick={() => redirect(CREATE_WITHDRAW_WALLET_PAGE_PATH)}
      />
      <GiftUserContainer />
      <WithdrawUserContainer />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  gap: ${Spacing(7)};
  flex-direction: column;
  width: 100%;
`;
