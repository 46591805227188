import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const createEvent = (payload: any) => {
  return HttpRequest({
    method: API.TYPE,
    url: API.URL,
    data: payload,
  });
};
