export const MODULE_NAME = 'ADMIN_ANALYTIC_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: '/user/analytic',
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface DATA_INTER {
  total: number;
  allReceipt: number;
}
