import React from 'react';

import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from './constant';
import { createWithdraw } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { required } from '../../lib/validation/service';
import { WITHDRAW_LIST_MODULE_NAME } from '../withdraw-list';
import { USER_WITHDRAW_TYPE } from '../withdraw-list/constant';
import { USER_BALANCE_MODULE_NAME } from '../user-balance';
import { useHistory } from 'react-router';
import { BALANCE_PAGE_PATH } from '../../page/balance';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';

export const Container: React.FC<{}> = ({}) => {
  const query = useQueryClient();
  const history = useHistory();

  const redirect = (path: string) => {
    history.push(path);
  };

  const onSuccess = () => {
    query.invalidateQueries(WITHDRAW_LIST_MODULE_NAME);
    query.invalidateQueries(USER_BALANCE_MODULE_NAME);
    query.invalidateQueries(USER_LIST_SHORT_MODULE_NAME);

    formik.resetForm();
    redirect(BALANCE_PAGE_PATH);
  };

  const action = useMutation(
    () => {
      return createWithdraw({
        ...formik.values,
        type: USER_WITHDRAW_TYPE.MONEY,
      });
    },
    {
      onSuccess,
    },
  );

  const config = {
    [FORM_VALUE_ENUM.AMOUNT]: [required],
    [FORM_VALUE_ENUM.WALLET]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.AMOUNT]: '',
    [FORM_VALUE_ENUM.WALLET]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {
      return action.mutate();
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, Array.isArray(e) ? e : [e]);
  };

  return (
    <Component
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      isFieldError={isFieldError}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      formik={formik}
    />
  );
};
