export enum WITHDRAW_STATUS {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  CANCEL = 'Cancel',
  FOR_PAYMENT = 'For_payment',
}

export const MODULE_NAME = 'WITHDRAW_ITEM_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  STATUS = 'status',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.STATUS]: WITHDRAW_STATUS | undefined;
}

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: (id?: string) => `withdraw/update-withdraw/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
