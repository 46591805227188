import React from 'react';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import { updateData } from './action';
import { EARNING_LIST_MODULE_NAME } from '../earning-list';
import { OPTION_ITEM_DATA } from '../../common/select/constant';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';
export const Container: React.FC<{
  id?: string;
  payload?: {
    amount: number;
    status: OPTION_ITEM_DATA;
    event: OPTION_ITEM_DATA;
    comment: string;
    personData: string;
    isPerson: boolean;
  };
  disabled?: boolean;
  handleCloseCard: () => void;
}> = ({ id, payload, disabled, handleCloseCard }) => {
  const query = useQueryClient();

  const onSuccess = () => {
    query.invalidateQueries(EARNING_LIST_MODULE_NAME);
    query.invalidateQueries(USER_LIST_SHORT_MODULE_NAME);

    handleCloseCard();
  };

  const action = useMutation(() => updateData({ id: id, payload }), {
    onSuccess,
  });

  const handleUpdate = () => {
    action.mutate();
  };

  const isSubmitDisabled = () => {
    if (disabled) {
      return true;
    }

    if (action.isLoading) {
      return action.isLoading;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isSuccess = () => {
    return action.isSuccess;
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  return (
    <Component
      isLoading={isLoading()}
      handleUpdate={handleUpdate}
      isSubmitDisabled={isSubmitDisabled}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
