import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { TextElem } from '../text';

export const Elem: React.FC<{}> = ({}) => {
  return (
    <Container>
      <TextElem
        type="medium"
        size="semiSmall"
        color="textSecondary"
        tid="COMMON.EMPTY_LIST"
        lineHeight
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${Spacing(6)};
  align-items: center;
  justify-content: start;
  border-radius: ${Spacing(3)};
  border: 1px solid rgba(229, 180, 95, 0.15);
  padding: 19px;

  span {
    line-height: 1em !important;
  }
`;
