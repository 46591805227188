import React, { useState } from 'react';
import styled from 'styled-components';

import { TabElem } from '../../common/tab';
import { FORM_VALUE_ENUM, tabList, typeList } from './constant';
import { Spacing } from '../../theme';
import { SelectElem } from '../../common/select';
import { FieldTimeInputElem } from '../../common/field-time-input';
import { customUserOption } from '../../data/user/option';

export const Component: React.FC<{
  activeTab: string;
  setActiveTab: Function;
  setUserId: Function;
  setType: Function;
  setDate: Function;
  userList: {
    label: string;
    value: string;
  }[];
  handleSelectUserChange: Function;
}> = ({
  activeTab,
  setActiveTab,
  setDate,
  setUserId,
  setType,
  userList,
  handleSelectUserChange,
}) => {
  const onChangeTime = (_first: any, second: any) => {
    setDate(second);
  };

  const onChangeUser = (
    _e: any,
    data: {
      label: string;
      value: string;
    },
  ) => {
    setUserId(data?.value);
  };

  const onChangeType = (
    _e: any,
    data: {
      label: string;
      value: string;
    },
  ) => {
    setType(data?.value);
  };

  return (
    <Container>
      <TabElem active={activeTab} setActive={setActiveTab} tabList={tabList} />
      <Double>
        <SelectElem
          isClearable
          dynamic
          placeholder="WITHDRAW.CREATE.USER"
          options={userList}
          filterOption={() => true}
          name={FORM_VALUE_ENUM.USER}
          onInputChange={handleSelectUserChange}
          onChange={onChangeUser}
          customComponents={customUserOption}
        />
        <SelectElem
          isClearable
          dynamic
          placeholder="WITHDRAW.CREATE.TYPE"
          options={typeList}
          name={FORM_VALUE_ENUM.TYPE}
          onChange={onChangeType}
        />
      </Double>
      <FieldTimeInputElem name={FORM_VALUE_ENUM.DATE} onChange={onChangeTime} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  margin-bottom: ${Spacing(2)};
`;

const Double = styled.div`
  margin-top: ${Spacing(1)};
  display: flex;
  gap: ${Spacing(2)};
  width: 100%;
`;
