import { FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => ({
  ...data,
  tag: Array.isArray(data.tag) ? Number(data.tag[0].value) : null,
  category: Array.isArray(data.category)
    ? Number(data.category[0].value)
    : null,
  status: Number(data.status[0].value),
});
