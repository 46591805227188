import React, { useEffect, useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MUTATION_PROPS,
} from './constant';

import { Component } from './component';
import { maxLength, required, urlOptional } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation } from 'react-query';
import { action as fetch } from './action';
import { convert } from './convert';

export const Container: React.FC<{}> = ({}) => {
  const onSuccess = (_data: unknown, { query }: MUTATION_PROPS) => {
    if (query !== 'test') {
      formik.resetForm();
    }
  };

  const config = {
    [FORM_VALUE_ENUM.BUTTON_LINK]: [urlOptional],
    [FORM_VALUE_ENUM.MESSAGE]: [required, maxLength(1000)],
    [FORM_VALUE_ENUM.PHOTO_LINK]: [urlOptional],
    [FORM_VALUE_ENUM.BUTTON_NAME]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.MESSAGE]: '',
    [FORM_VALUE_ENUM.BUTTON_LINK]: '',
    [FORM_VALUE_ENUM.PHOTO_LINK]: '',
    [FORM_VALUE_ENUM.BUTTON_NAME]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      // return action.mutate(convert(values));
    },
  });

  const action = useMutation(
    ({ values, query }: { values: FORM_VALUE_INTER; query: string }) =>
      fetch(values, query),
    {
      onSuccess,
    },
  );

  const onSubmit = (query: string) => {
    return action.mutate({ values: convert(formik.values), query });
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  return (
    <Component
      onSubmit={onSubmit}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
    />
  );
};
