import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';

import { AlertActionElem } from '../../common/alert-action';

import { DATA_INTER } from './constant';
import { i18n } from '../../lib/lang';
import { Spacing } from '../../theme';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import { SkeletonItem } from '../../common/skeleton-item';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  return (
    <>
      {isError && (
        <AlertActionElem type="error" text={i18n.t(`${errorMessage}`)} />
      )}
      <Double>
        <ChipContainerGold isError={isError}>
          <TextElemStyled
            color="white"
            size="verySmall"
            type="medium"
            tid="ANALITIC.USER.BALANCE"
          />
          {isLoading && <SkeletonItem />}
          {isSuccess && (
            <TextElemStyled
              color="default"
              size="title"
              type="bold"
              tid={`$ ${data?.balance ? String(data?.balance) : ''}`}
            />
          )}
        </ChipContainerGold>
        <ChipContainer isError={isError}>
          <TextElemStyled
            color="white"
            size="verySmall"
            type="medium"
            tid="ANALITIC.USER.PENDING_BALANCE"
          />
          {isLoading && <SkeletonItem />}
          {isSuccess && (
            <TextElemStyled
              color="white"
              size="title"
              type="bold"
              tid={`$ ${
                data?.pendingBalance ? String(data?.pendingBalance) : ''
              }`}
            />
          )}
        </ChipContainer>
      </Double>
    </>
  );
};

const ChipContainerGold = styled.div<{ isError?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: ${({ isError }) => isError && '73px'};
  justify-content: ${({ isError }) => (isError ? 'start' : 'center')};

  align-items: start;
  padding: ${Spacing(4)};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;

  border: solid 1px ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
`;

const SkeletonTitle = styled(Skeleton)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.SKELETON_TITLE]};

  margin: 0;
  height: 20px;
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
`;

const TextElemStyled = styled(TextElem)`
  line-height: 1em;
`;

const ChipContainer = styled.div<{ isError?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: ${({ isError }) => isError && '73px'};
  justify-content: ${({ isError }) => (isError ? 'start' : 'center')};

  align-items: start;
  padding: ${Spacing(4)};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;

  border: solid 1px rgba(229, 180, 95, 0.15);
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(3)};
`;
