import React from 'react';
import { FormikValues, useFormik } from 'formik';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import { updateData } from './action';
import { USER_LIST_MODULE_NAME } from '../user-list';
export const Container: React.FC<{
  userId?: string;
  formData?: {
    role?: number;
    level?: number;
  };
}> = ({ userId, formData }) => {
  const query = useQueryClient();

  const onSuccess = () => {
    query.invalidateQueries(USER_LIST_MODULE_NAME);
  };

  const action = useMutation(() => updateData(userId, formData), { onSuccess });

  const initialValues = {
    [FORM_VALUE_ENUM.ROLE]: formData?.role,
    [FORM_VALUE_ENUM.LEVEL]: formData?.level,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    // enableReinitialize: true,
    onSubmit: () => {
      action.mutate();
    },
  });

  const isSubmitDisabled = () => {
    return action.isLoading;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isSuccess = () => {
    return action.isSuccess;
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  return (
    <Component
      isSubmitDisabled={isSubmitDisabled}
      isSuccess={isSuccess()}
      formik={formik}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
