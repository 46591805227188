import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export interface FETCH_PARAM_INTER {
  pageParam?: number;
  pageSize?: number;
}

export const action = async ({
  pageParam,
  pageSize,
}: FETCH_PARAM_INTER): Promise<any> => {
  let params = '?';

  if (pageParam) {
    params += `page=${pageParam}`;
  }

  if (pageSize) {
    params += `&pageSize=${pageSize}`;
  }

  params.slice(0, -1);

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
