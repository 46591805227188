import { HttpRequest } from '../../lib/http';
import { API, API_USER } from './constant';

export const action = ({ search }: any) => {
  let params = '?';

  if (search) {
    params += `name=${search}`;
  }

  return HttpRequest({
    method: API.EVENT.TYPE,
    url: API.EVENT.URL(params),
  });
};

export const actionUserId = () => {
  return HttpRequest({
    method: API.USER.TYPE,
    url: API.USER.URL,
  });
};

export const actionList = () => {
  return HttpRequest({
    method: API_USER.TYPE,
    url: API_USER.URL,
  });
};
