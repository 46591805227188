import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';
import { CopyElem } from '../../../common/copy';
import { TextElem } from '../../../common/text';
import iconLink from '../../../asset/svg/button/link-grey.svg';

export const CopyField: React.FC<{ value?: string; tid: string }> = ({
  value,
  tid,
}) => {
  return (
    <CopyElem value={value || ''}>
      <ContainerClickable>
        <ChipContainerClickable>
          <TextElem
            color="textSecondary"
            size="small"
            type="medium"
            tid={tid}
          />
          <TextElem
            color="textThird"
            size="small"
            type="medium"
            tid={value || 'Не указан'}
          />
        </ChipContainerClickable>
        <ImgStyled src={iconLink} alt="copy" />
      </ContainerClickable>
    </CopyElem>
  );
};

const ImgStyled = styled.img`
  position: absolute;
  right: ${Spacing(3)};
  top: 50%;
  transform: translateY(-50%);
`;

const ChipContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
`;

const ChipContainerClickable = styled(ChipContainer)`
  padding: unset !important;
`;

const ContainerClickable = styled.div`
  position: relative;
  padding: ${Spacing(3)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  gap: ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
  width: 100%;

  display: flex;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
