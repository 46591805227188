import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { convert } from './convert';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import {
  ACTION_RESPONSE_INTER as ACTION_RESPONSE_EVENT_INTER,
  DATA_INTER as DATA_EVENT_INTER,
} from '../event-list/constant';

import { action as eventAction } from '../event-list/action';
import { usePaginatedData } from '../../lib/util/usePagintationData';

export const Container: React.FC<{
  payload?: {
    status: string;
    eventId?: string;
    userId?: string | number;
    startDate?: string;
    endDate?: string;
  };
}> = ({ payload }) => {
  const [selectEventValue, setSelectEventValue] = useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const pageSize = 10;

  const onSuccess = (data: any) => {
    const totalPage = data?.pages[0].total;

    if (totalPage) {
      setPageCount(totalPage);
    }
  };

  const {
    data: preFetch,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = usePaginatedData(
    action,
    MODULE_NAME,
    {
      pageParam: page,
      pageSize,
      ...payload,
    },
    onSuccess,
  );

  useEffect(() => {
    setPage(1);
  }, [payload]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const earningList = useMemo(() => {
    const list = preFetch?.pages[0].list as unknown as any;

    if (list) {
      const convertedList = convert(list);

      return { ...convertedList };
    }
  }, [preFetch]);

  const preFetchEvent = useQuery(EVENT_LIST_MODULE_NAME, () =>
    eventAction({ search: selectEventValue }),
  );

  const handleSelectEventChange = (newValue: any, _actionMeta: any) => {
    setSelectEventValue(newValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchEvent.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectEventValue]);

  const getDataEvent = (): DATA_EVENT_INTER | undefined => {
    const data = preFetchEvent.data as unknown as ACTION_RESPONSE_EVENT_INTER;

    if (data) {
      return { ...data, isEmpty: data?.list?.length < 1 };
    }
  };

  const getErrorMessage = () => {
    const errorObj: ACTION_ERROR_INTER = error as ACTION_ERROR_INTER;

    if (error) {
      return errorObj.message;
    }
  };

  return (
    <Component
      handleSelectEventChange={handleSelectEventChange}
      data={earningList}
      dataEvent={getDataEvent()}
      isLoading={isLoading || isFetching}
      isError={isError && !isLoading}
      isSuccess={isSuccess && !isFetching}
      errorMessage={getErrorMessage()}
      pageSize={pageSize}
      page={page}
      onChangePage={handlePageChange}
      pageCount={pageCount}
    />
  );
};
