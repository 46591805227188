import VALIDATION from './validation.json';
import ERROR from './error.json';
import COMMON from './common.json';
import NAVIGATION from './navigation.json';
import LANG from './lang.json';
import ANALITIC from './analitic.json';
import HEADER from './header.json';
import USER from './user.json';
import ADDITIONAL from './additional.json';
import FAQ from './faq.json';
import EARNING from './earning.json';
import WITHDRAW from './withdraw.json';
import EVENT from './event.json';
import EDUCATION from './education.json';

export const RU = {
  translation: {
    VALIDATION,
    ERROR,
    COMMON,
    NAVIGATION,
    LANG,
    ANALITIC,
    HEADER,
    USER,
    ADDITIONAL,
    FAQ,
    EARNING,
    WITHDRAW,
    EVENT,
    EDUCATION,
  },
};
