import React from 'react';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { GIFT_DETAIL } from '../constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { i18n } from '../../../lib/lang';
import { TextElem } from '../../../common/text';
import { UserGiftPickUpContainer } from '../../user-gift-pick-up';
import { USER_GIFT } from '../../../data/user/constant';

export const SliderCarousel: React.FC<{ list: GIFT_DETAIL[] }> = ({ list }) => {
  const isOneElem = list.length === 1;

  const items = Array.from({ length: list.length }, (_, index) => {
    const item = list[index];
    const imgSrc = item?.img ? i18n.t(item.img) : '';
    const title = item?.title ? i18n.t(item.title) : '';
    const price = item?.price ? i18n.t(item.price) : '';
    const id = item?.id;
    const isMerch = item?.id === USER_GIFT.MERCH;

    return (
      <SliderItem key={index}>
        <ImgStyled src={imgSrc} />
        <GiftContainer>
          <TitleContainer>
            <TextElem
              color="white"
              size="semiSmall"
              type="semi-bold"
              tid={title}
            />
            {!isMerch && (
              <TextElem
                color="white"
                size="small"
                tid="USER.GIFT.PRICE"
                tvalue={{ value: price }}
              />
            )}
            {isMerch && (
              <TextElem color="white" size="small" tid="USER.GIFT.MERCH" />
            )}
          </TitleContainer>
          <UserGiftPickUpContainer id={id} />
        </GiftContainer>
      </SliderItem>
    );
  });

  return (
    <CarouselContainer>
      <SliderWrapper isOneElem={isOneElem}>
        <SliderTrack>
          {items}
          {!isOneElem && <SpacerItem />}
        </SliderTrack>
      </SliderWrapper>
    </CarouselContainer>
  );
};

const GiftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const ImgStyled = styled.img`
  height: 120px;
  width: 120px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
`;

const SliderWrapper = styled.div<{ isOneElem: boolean }>`
  display: flex;
  overflow-x: ${({ isOneElem }) => (isOneElem ? 'hidden' : 'auto')};
  white-space: nowrap;
  scroll-behavior: smooth;
  padding: 0 20px;
  padding-bottom: ${Spacing(2)};
`;

const CarouselContainer = styled.div`
  align-items: center;
  width: calc(100vw);
  position: relative;
  right: 20px;
`;

const SliderTrack = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${Spacing(4)};
`;

const SpacerItem = styled.div`
  flex: 0 0 auto;
  width: 30px;
  display: flex;
  gap: ${Spacing(3)};
  justify-content: start;
  align-items: center;
`;

const SliderItem = styled.div`
  flex: 0 0 auto;
  width: 314px;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: start;
  align-items: center;
`;
