import React from 'react';

import styled, { css } from 'styled-components';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_ICON_DATA,
  SIZE_ICON_TYPE,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE, ROLE_ENUM, ROLE_TYPE } from './constant';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { Spacing } from '../../theme';

export const Elem: React.FC<PROPS_TYPE> = ({
  children,
  tid,
  tvalue,
  color = 'textSecondary',
  disabled = false,
  onClick,
  iconSize = 'small',
  iconLeft,
  textType = 'semi-bold',
  ref,
  className,
  sizeText = 'small',
  iconRight,
  type = 'button',
}) => {
  const isIcon = iconLeft || iconRight;

  const handleClick = (e: any) => {
    if (onClick) onClick(e);
  };
  return (
    <ButtonStyled
      onClick={handleClick}
      disabled={disabled}
      className={className}
      ref={ref}
      type={type}
    >
      <Content isIcon={isIcon}>
        {iconLeft && <Icon src={iconLeft} iconSize={iconSize} color={color} />}
        {tid ? (
          <TextElem
            tid={tid}
            tvalue={tvalue}
            size={sizeText}
            type={textType}
            color={color}
          />
        ) : (
          children
        )}
        {iconRight && (
          <Icon
            disabled={disabled}
            src={iconRight}
            iconSize={iconSize}
            color={color}
          />
        )}
      </Content>
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button<{ type: ROLE_TYPE | any }>`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  width: 100%;
  max-height: 44px;

  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover:not(:active) {
        opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.BUTTON]};
      }
    `}

  span {
    line-height: 1em;
  }

  ${({ theme, disabled = false, type }) =>
    type === ROLE_ENUM.COPY &&
    css`
      background: ${theme[COLOR_ENUM.BUTTON_SECONDARY]};

      & > div > span {
        color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
      }

      padding: 11px;

      border-radius: ${SIZE_BORDER_RADIUS_DATA[
        SIZE_BORDER_RADIUS_ENUM.BUTTON
      ]}px;

      ${!disabled &&
      css`
        :active {
          background: ${theme[COLOR_ENUM.BUTTON_SECONDARY_ACTIVE]};
        }
      `}

      ${disabled &&
      css`
        opacity: 0.5;
      `}
    `}

  ${({ theme, disabled = false, type }) =>
    type === ROLE_ENUM.SUBMIT &&
    css`
      div {
        span {
          color: ${theme[COLOR_ENUM.DEFAULT]};
        }
      }
      background: ${theme[COLOR_ENUM.BUTTON_SECONDARY]};

      & > div > span {
        background-image: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
      }

      padding: 14px 20px;

      border-radius: ${SIZE_BORDER_RADIUS_DATA[
        SIZE_BORDER_RADIUS_ENUM.BUTTON
      ]}px;

      ${!disabled &&
      css`
        :active {
          background: ${theme[COLOR_ENUM.BUTTON_SECONDARY_ACTIVE]};
        }
      `}

      ${disabled &&
      css`
        opacity: 0.5;
      `}
    `}

    ${({ theme, disabled = false, type }) =>
    type === ROLE_ENUM.PRIMARY &&
    css`
      background: ${theme[COLOR_ENUM.BUTTON_PRIMARY]};

      & > div > span {
        color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
      }

      padding: 14px;

      border-radius: ${SIZE_BORDER_RADIUS_DATA[
        SIZE_BORDER_RADIUS_ENUM.BUTTON
      ]}px;

      ${!disabled &&
      css`
        :active {
          background: ${theme[COLOR_ENUM.BUTTON_SECONDARY_ACTIVE]};
        }
      `}

      ${disabled &&
      css`
        opacity: 0.5;
      `}
    `}
`;

const Content = styled.div<{ isIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: ${Spacing(1.5)};
`;

const Icon = styled.img<{
  color?: COLOR_TYPE;
  iconSize: SIZE_ICON_TYPE;
  disabled?: boolean;
}>`
  ${({ color = COLOR_ENUM.DEFAULT, iconSize, disabled }) => css`
    fill: ${({ theme }) => theme[color]};
    opacity: ${disabled && '0.5'};

    height: ${SIZE_ICON_DATA[iconSize]}px;
    width: ${SIZE_ICON_DATA[iconSize]}px;
  `}
`;
