import React from 'react';

import { AccordionElem } from '../../common/accordion';
import { DATA } from './constant';
import styled from 'styled-components';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';

export const Component: React.FC<{}> = () => {
  const chatUrl = i18n.t('FAQ.BUTTON.LINK');

  const handleClick = () => {
    window.Telegram.WebApp.openLink(chatUrl);
    setTimeout(() => {
      window.Telegram.WebApp.close();
    }, 100);
  };

  return (
    <>
      <Container>
        <AccordionElem data={DATA} />
      </Container>
      <ButtonWrapper>
        <ButtonElem
          type="primary"
          tid="FAQ.BUTTON.TITLE"
          onClick={handleClick}
        />
      </ButtonWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  margin-top: 26px;
  width: 100%;
  margin-bottom: ${Spacing(33)};

  button {
    max-height: 45px !important;
    padding: 14px !important;
  }

  span {
    font-size: 14px !important;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
