import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { Spacing } from '../../theme';
import { SelectElem } from '../../common/select';
import { ButtonElem } from '../../common/button';
import { COLOR_ENUM } from '../../theme/color';
import { FieldTextElem } from '../../common/field-text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { i18n } from '../../lib/lang';
import { FORM_VALUE_ENUM } from './constant';
import { CheckBoxElem } from '../../common/checkbox';
import { customUserOption } from '../../data/user/option';

export const Component: React.FC<{
  userList: {
    label: string;
    value: string;
  }[];
  eventList: {
    label: string;
    value: string;
  }[];
  formik: FormikValues;
  getFieldError: Function;
  isSubmitDisabled: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSubmit?: any;
  getFieldValue: Function;
  isFieldError: Function;
  setFieldValue: Function;
  handleButtonClick: Function;
  isOpen: boolean;
  handleSelectUserChange: Function;
  handleSelectEventChange: Function;
}> = ({
  userList,
  eventList,
  isError,
  errorMessage,
  isSuccess,
  isLoading,
  isSubmitDisabled,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  setFieldValue,
  handleButtonClick,
  isOpen,
  handleSelectUserChange,
  handleSelectEventChange,
}) => {
  return (
    <>
      <Container>
        {!isOpen && (
          <ButtonElem
            type="primary"
            tid="Создать"
            onClick={handleButtonClick}
          />
        )}
        {isOpen && (
          <ExpandContainer>
            <SaveContainer>
              <SelectElem
                placeholder="EARNING.CREATE.USER"
                dynamic
                isClearable
                filterOption={() => true}
                onInputChange={handleSelectUserChange}
                options={userList}
                name={FORM_VALUE_ENUM.USER}
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.USER)}
                error={isFieldError(FORM_VALUE_ENUM.USER)}
                customComponents={customUserOption}
              />
              <ButtonElem
                onClick={formik.handleSubmit}
                disabled={isSubmitDisabled()}
                type="submit"
                tid="EARNING.CREATE.SAVE"
              />
            </SaveContainer>
            <FieldTextElem
              icon="$"
              placeholder="EARNING.CREATE.AMOUNT"
              name={FORM_VALUE_ENUM.AMOUNT}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
              error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
              type="number"
            />
            <SelectElem
              placeholder="EARNING.CREATE.EVENT"
              dynamic
              isClearable
              filterOption={() => true}
              options={eventList}
              onInputChange={handleSelectEventChange}
              name={FORM_VALUE_ENUM.EVENT}
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.EVENT)}
              error={isFieldError(FORM_VALUE_ENUM.EVENT)}
            />
            <FieldTextAreaElem
              placeholder="EARNING.CREATE.COMMENT"
              name={FORM_VALUE_ENUM.COMMENT}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.COMMENT)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.COMMENT)}
              error={isFieldError(FORM_VALUE_ENUM.COMMENT)}
            />
            <CheckBoxElem
              name={FORM_VALUE_ENUM.PERSON}
              onChange={formik.handleChange}
              textSize="semiSmall"
              tid="EARNING.CREATE.PERSON"
            />
            {formik.values[FORM_VALUE_ENUM.PERSON] && (
              <FieldTextAreaElem
                placeholder="EARNING.CREATE.PERSON_DATA"
                name={FORM_VALUE_ENUM.PERSON_DATA}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.PERSON_DATA)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.PERSON_DATA)}
                error={isFieldError(FORM_VALUE_ENUM.PERSON_DATA)}
              />
            )}
          </ExpandContainer>
        )}
      </Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </>
  );
};

const SaveContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const ExpandContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  padding: ${Spacing(4)} 0 0 0;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(3)};
`;
