import React from 'react';

import styled, { keyframes } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import ReactDOM from 'react-dom';

export const Elem: React.FC = () => {
  return ReactDOM.createPortal(
    <LoaderContainer>
      <LoaderBar />
    </LoaderContainer>,
    document.body,
  );
};

const load = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
  z-index: 9999;
`;

const LoaderBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  animation: ${load} 0.9s infinite linear;
`;
