import React, { useState } from 'react';
import styled from 'styled-components';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import {
  EVENT_CATEGORY_VIEW,
  EVENT_ITEM_DATA_RAW_INTER,
  EVENT_STATUS_VIEW,
  EVENT_TAG,
  EVENT_TAG_VIEW,
} from '../../event-list/constant';
import { COLOR_ENUM } from '../../../theme/color';
import { EventItemUpdateContainer } from '../../event-item-update';

export const Card: React.FC<{
  data: EVENT_ITEM_DATA_RAW_INTER;
  isOpen: boolean;
  onClick: () => void;
}> = ({ data, isOpen, onClick }) => {
  return (
    <>
      {!isOpen && (
        <>
          <Container
            className="user_container"
            isOpen={isOpen}
            onClick={onClick}
          >
            <ImgStyled src={data.imgUrl} />
            <InfoContainer>
              <TextElem
                size="semiSmall"
                color="white"
                type="semi-bold"
                tid={data.title}
                oneLine
              />
              <InfoList>
                {data.category && (
                  <>
                    <TextElem
                      color="textPrimary"
                      size="verySmall"
                      type="medium"
                      tid={EVENT_CATEGORY_VIEW[data.category]}
                    />
                    <Dot />
                  </>
                )}
                {data.tag && (
                  <>
                    <TextElem
                      color={
                        data.tag === EVENT_TAG.NEW ? 'tag' : 'tagSecondary'
                      }
                      size="verySmall"
                      type="medium"
                      tid={EVENT_TAG_VIEW[data.tag]}
                    />
                    <Dot />
                  </>
                )}

                <TextElem
                  color="textPrimary"
                  size="verySmall"
                  type="medium"
                  tid={EVENT_STATUS_VIEW[data.status]}
                />
              </InfoList>
            </InfoContainer>
          </Container>
        </>
      )}

      {isOpen && <EventItemUpdateContainer data={data} />}
    </>
  );
};

const ImgStyled = styled.img`
  height: 40px;
  width: 70px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.TAG]}px;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DOT]};
`;

const InfoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing(1)};
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
`;

const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: ${Spacing(2)};
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: start;
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
