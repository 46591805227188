import React, { ReactNode } from 'react';
import { Skeleton } from 'antd';

import styled from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { GridElem } from '../grid';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';

export const Elem: React.FC = ({}) => {
  return (
    <GridElem spacing={3}>
      {[1].map(() => (
        <Container>
          <SkeletonTitle active />
          <SkeletonTitle active />
        </Container>
      ))}
    </GridElem>
  );
};

const Container = styled.div`
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.SKELETON]};
`;

const SkeletonTitle = styled(Skeleton)`
  padding: unset;

  gap: ${Spacing(5)};
  margin: unset;
  margin: unset;
  width: 100%;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};

  .ant-skeleton-content {
    ul {
      margin: 0 0 12px !important;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    li {
      margin: unset !important;
      width: 100% !important;
    }
    .ant-skeleton-paragraph {
      margin: unset !important;
      display: none;
    }
    .ant-skeleton-title {
      width: 100% !important;
      border-radius: ${SIZE_BORDER_RADIUS_DATA[
        SIZE_BORDER_RADIUS_ENUM.CONTENT
      ]}px;

      height: 80px;
      margin: unset;
      background-image: linear-gradient(
        90deg,
        rgba(110, 108, 108, 0.06) 25%,
        rgba(165, 161, 139, 0.08) 37%,
        rgba(107, 104, 104, 0.06) 63%
      ) !important;
    }
  }
`;
