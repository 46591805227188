export const MODULE_NAME = 'USER_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const EARNING_USER_LIST_MODULE_NAME = 'EARNING_USER_LIST_MODULE_NAME';

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const API = {
  TYPE: 'POST',
  URL: 'earning/create-earning',
};

export enum FORM_VALUE_ENUM {
  USER = 'user',
  AMOUNT = 'amount',
  EVENT = 'event',
  COMMENT = 'comment',
  PERSON = 'isPerson',
  PERSON_DATA = 'personData',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.USER]: {
    value: string;
    label: string;
  }[];
  [FORM_VALUE_ENUM.AMOUNT]: string;
  [FORM_VALUE_ENUM.EVENT]: {
    value: string;
    label: string;
  }[];
  [FORM_VALUE_ENUM.COMMENT]: string;
  [FORM_VALUE_ENUM.PERSON_DATA]: string;
  [FORM_VALUE_ENUM.PERSON]: boolean;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export const tabList = [
  { tid: 'EARNING.TAB.ALL', value: 1 },
  { tid: 'EARNING.TAB.PENDING', value: 2 },
  { tid: 'EARNING.TAB.COMPLETE', value: 3 },
  { tid: 'EARNING.TAB.CANCEL', value: 4 },
];
