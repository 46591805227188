import React from 'react';

import styled from 'styled-components';

import { GridElem } from '../../common/grid';
import { Spacing } from '../../theme';
import { CardEducation } from './frame/card';
import { DATA } from './constant';

export const Component: React.FC = () => {
  return (
    <GridElemStyled spacing={6}>
      {DATA.map((e) => (
        <CardEducation {...e} />
      ))}
    </GridElemStyled>
  );
};

const GridElemStyled = styled(GridElem)`
  margin-top: ${Spacing(1)};
`;
