import { Dispatch, GetState } from '../../lib/store';
import {
  ACTION_DATA_INTER,
  ACTION_TYPE,
  LOCAL_STORE_INTER,
  META_DATA_INTER,
  MODULE_NAME,
  STORE_INTER,
  API,
  GET_TOKEN_ACTION_RESPONSE_INTER,
} from './constant';
import { jwtTokenDecode, setAuthorization } from '../../lib/jwt';
import { appLoadLocalStateData, appSaveLocalStateData } from '../app';
import { AUTH_MODULE_NAME } from './index';
import { HttpRequest } from '../../lib/http';
import { getTelegramUserData } from '../../lib/util/telegramData';

export const setAuthData = (jwtToken: string) => async (dispatch: Dispatch) => {
  const jwtData: any = jwtTokenDecode(jwtToken);
  const storeData: LOCAL_STORE_INTER = await appLoadLocalStateData(MODULE_NAME);

  if (jwtData) {
    setAuthorization(jwtToken);

    const data: ACTION_DATA_INTER = {
      user: {
        ...jwtData,
      },
    };
    const localStateData: LOCAL_STORE_INTER = {
      token: jwtToken,
      user: data.user,
    };

    await appSaveLocalStateData(MODULE_NAME, localStateData);

    return dispatch({
      type: ACTION_TYPE.SET_DATA,
      data,
    });
  }
};

export const resetAuthData =
  () => async (dispatch: Dispatch, getState: GetState) => {
    setAuthorization(null);

    const store: STORE_INTER = await getState()[AUTH_MODULE_NAME];

    const localStateData: LOCAL_STORE_INTER = {
      token: null,
      user: null,
    };

    await appSaveLocalStateData(MODULE_NAME, localStateData);

    return dispatch({
      type: ACTION_TYPE.CLEAR_DATA,
    });
  };

export const setAuthHaveWellcomeData =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const store: STORE_INTER = await getState()[AUTH_MODULE_NAME];

    const newMetadata: META_DATA_INTER = {
      haveWellcome: true,
    };

    const localStoreData = await appLoadLocalStateData(MODULE_NAME);

    await appSaveLocalStateData(MODULE_NAME, {
      ...localStoreData,
      meta: {
        ...newMetadata,
      },
    });

    return dispatch({
      type: ACTION_TYPE.SET_META_DATA,
      data: {
        meta: newMetadata,
      },
    });
  };

export const updateAuthData = () => async (dispatch: Dispatch) => {
  const telegramUserData = getTelegramUserData();

  if (!telegramUserData) {
    return null;
  }

  const { user, hash, initDataString } = telegramUserData;

  const telegramData = {
    first_name: user?.first_name,
    hash: hash,
    id: user?.id,
    last_name: user?.last_name,
    username: user?.username,
    language_code: user?.language_code,
    allows_write_to_pm: user?.allows_write_to_pm || false,
    initDataString: initDataString,
  };

  try {
    const resData: any = await HttpRequest({
      method: API.GET_TOKEN.TYPE,
      url: API.GET_TOKEN.URL,
      data: telegramData,
    });

    const data = resData as GET_TOKEN_ACTION_RESPONSE_INTER;

    await dispatch(setAuthData(data.accessToken));
  } catch (e) {
    console.log(String(e));
    // alert(JSON.stringify(e));
  }
};

export const preloadAuthData = () => async (dispatch: Dispatch) => {
  const storeData: LOCAL_STORE_INTER = await appLoadLocalStateData(MODULE_NAME);
  console.warn('LOCAL STORE LOADED', storeData);

  if (storeData) {
    if (storeData.token) {
      setAuthorization(storeData.token);
    }

    return dispatch({
      type: ACTION_TYPE.SET_DATA,

      data: {
        user: storeData?.user,
      },
    });
  }
};
