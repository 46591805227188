import React from 'react';
import styled from 'styled-components';
import { Collapse, Space } from 'antd';

import { MarkDown } from '../../common/markdown/markdown';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import arrowIcon from '../../asset/svg/common/downArrow.svg';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { i18n } from '../../lib/lang';

export const Elem: React.FC<{
  data: { label: string; children: string }[];
}> = ({ data }) => {
  const t = i18n.t;
  return (
    <SpaceStyled direction="vertical">
      <CollapseStyled
        accordion
        expandIconPosition="end"
        destroyInactivePanel={true}
        expandIcon={() => <img src={arrowIcon} />}
        items={data.map((e) => ({
          key: e.label,
          label: t(e.label),
          children: <MarkDown tid={e.children} />,
        }))}
      />
    </SpaceStyled>
  );
};

const SpaceStyled = styled(Space)`
  width: 100%;
  gap: ${Spacing(3)} !important;
`;

const CollapseStyled = styled(Collapse)`
  border: unset;
  padding: unset !important
  ;
  .ant-collapse-item {
    border: unset !important;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px !important;
    padding: unset !important;

    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};

    margin-bottom: ${Spacing(3)};

    .ant-collapse-content {
      background-color: unset !important;
      border: unset !important;

      .ant-collapse-content-box {
        padding: 0 ${Spacing(4)} ${Spacing(4)};
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      .ant-collapse-expand-icon {
        transform: rotate(180deg);
        padding-inline-start: unset !important;
        transition: transform 0.3s ease;
      }
    }
  }

  .ant-collapse-header {
    padding: ${Spacing(4)} !important;

    .ant-collapse-expand-icon {
      transition: transform 0.3s ease;
      padding-inline-start: unset !important;
    }

    .ant-collapse-header-text {
      color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
      margin-inline-end: 5px;
    }
  }
`;
