import React, { useRef } from 'react';

import styled, { css } from 'styled-components';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';
import { useTranslation } from 'react-i18next';

export const Elem: React.FC<PROPS_TYPE> = ({
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  type,
  disabled,
  icon,
  placeholder,
  className,
  defaultValue,
  isRequired,
}) => {
  const refIcon = useRef<HTMLDivElement>(null);

  const handleChange = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    if (!e.target) e.target = {};

    e.target.name = name;
    e.target.value = e.target.value;

    if (onBlur) {
      onBlur(e);
    }
  };

  const iconSize = refIcon?.current?.clientWidth || 40;

  const { t } = useTranslation();

  const textRequired = t('COMMON.REQUIRED');
  const textTitle = t(title || '');

  if (disabled) {
    return <FakeInput>{value}</FakeInput>;
  } else {
    return (
      <Container className={className}>
        {!isRequired && title && (
          <TextElem
            tid={title}
            size="semiSmall"
            type="regular"
            color="textSecondary"
          />
        )}
        {isRequired && title && (
          <TitleStyled
            dangerouslySetInnerHTML={{ __html: `${textTitle}${textRequired}` }}
          />
        )}

        <Content>
          <Input
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={defaultValue}
            error={error}
            type={type}
            value={value}
            // value={inputValue}
            iconSize={iconSize}
            placeholder={placeholder && i18n.t(placeholder)}
            className={className}
          />
          <Icon ref={refIcon}>{icon}</Icon>
        </Content>
        {!!errorMessage && (
          <ErrorContainer className="errorContainer">
            <ErrorMessage>
              <TextElem size="alert" color="error">
                {errorMessage}
              </TextElem>
            </ErrorMessage>
          </ErrorContainer>
        )}
      </Container>
    );
  }
};

const TitleStyled = styled.span`
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
`;

const Input = styled.textarea<{
  error?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize: number;
}>`
  height: ${Spacing(22)};
  resize: none;
  cursor: pointer;
  :focus-visible {
    outline: none;
  }
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};

  padding: 16px;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  line-height: 1.3em;
  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  width: 100%;

  transition: all 0.2s;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  &:focus {
    border: double 1px transparent;
    background-image: linear-gradient(#1b1b1c, #1b1b1c),
      linear-gradient(to right, #f4e187, #e5b45f);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  ::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]} !important;
    font-size: 14px !important;
  }

  &:hover {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};

    ::placeholder {
      display: none;
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]} !important;
    }
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ error }) =>
    error &&
    css`
      /* border-color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important; */
    `}
`;

const FakeInput = styled.div`
  padding: ${Spacing(4)};
  height: 50px;
  font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.INPUT]}px;
  color: ${({ theme }) => theme[COLOR_ENUM.DISABLED]};
  line-height: 1em;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.DISABLED]};
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: start;
  gap: ${Spacing(2.5)};
`;

const Content = styled.div`
  position: relative;
  max-height: ${Spacing(22)};
`;

const ErrorContainer = styled.div`
  position: relative;
  line-height: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  width: 100%;
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
