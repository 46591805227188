import styled from 'styled-components';
import React from 'react';
import { DatePicker } from 'antd';

import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { ReactComponent as CalendarIcon } from '../../asset/svg/common/calendar.svg';
import ArrowIcon from '../../asset/svg/common/arrow.svg';
import { PROPS_TYPE } from './constant';
import { TextElem } from '../text';

export const Elem: React.FC<PROPS_TYPE> = ({
  onChange = () => {},
  title,
  fieldValue = [],
  ...props
}) => {
  const onChangeDate = (value: any, stringValue: any) => {
    onChange(value, stringValue);
  };

  return (
    <Container id="container" className="dateRange">
      {title && (
        <TextElem
          tid={title}
          type="medium"
          color="textSecondary"
          size="input"
        />
      )}

      <Field
        {...props}
        format="YYYY-MM-DD"
        onChange={onChangeDate}
        onCalendarChange={onChange}
        menuIsOpen
        suffixIcon={<CalendarIcon />}
        allowClear={{ clearIcon: <CloseIcon /> }}
        //@ts-ignore
        getPopupContainer={() => document.getElementById('container')}
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};

  .ant-picker-input > input::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }

  .ant-picker-separator {
    margin-right: ${Spacing(4)};
  }

  .ant-picker-input {
    input {
      max-width: max-content;
    }
  }

  .ant-picker-clear {
    inset-inline-end: 15px;
  }

  .ant-picker-suffix {
    position: relative;
    left: 2px;
  }

  .ant-picker-range-arrow {
    display: none !important;
  }
  .ant-picker-panel-container {
    background: transparent;

    .ant-picker-presets {
      min-height: 25rem !important;
    }
    @media (max-width: 800px) {
      overflow: scroll !important;
      height: 400px;
      .ant-picker-panel-layout {
        flex-direction: column !important;

        .ant-picker-presets {
          max-width: 100% !important;
          min-height: 10rem !important;
        }

        .ant-picker-panels,
        .ant-picker-datetime-panel {
          flex-direction: column !important;
        }
      }
    }
  }
  .ant-picker-panel {
    color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
  }

  .ant-picker-focused {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;

    color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
  }

  .ant-picker-outlined {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
  }

  .ant-picker-footer {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]} !important;
  }
  .ant-picker-panel-layout {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]} !important;
    overflow: auto;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
    min-height: 44px !important;
  }
  .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }

  .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.BACKGROUND_SECONDARY]} !important;
  }

  .ant-picker-content {
    thead {
      tr {
        th {
          color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
        }
      }
    }
    tbody {
      tr {
        td {
          color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
        }
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
  }

  .ant-picker-dropdown {
    button {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }

    .ant-picker-header-super-next-btn,
    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-super-prev-btn {
      &:hover {
        color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
  }

  .ant-picker-header-view {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;

    &:hover {
      button {
        color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      }
    }
  }
`;

const Field = styled(DatePicker.RangePicker)`
  min-height: 44px;
  box-sizing: border-box;

  .calendar-popup.ant-picker-range-arrow {
    display: none;
  }
  .calendar-popup.ant-picker-panel {
  }
  .ant-picker-range-separator {
    svg {
      path {
        d: path('M20 12H4M4 12L8.75676 6M4 12L8.75676 18');
      }
    }
    position: relative;
    ::after {
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 18px;
      background: url(${ArrowIcon});
      top: 1px;
      left: 5px;
    }
  }

  cursor: pointer;
  height: 44px;
  :focus-visible {
    outline: none;
  }
  position: relative;
  .ant-picker-active-bar {
    display: none;
  }
  box-shadow: none !important;
  .ant-picker-input > input {
    line-height: 1em !important;

    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    }
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  }
  text-align: start;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]} !important;

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
    /* background: ${({ theme }) => theme[COLOR_ENUM.INPUT]}; */

    .ant-picker-input > input {
      color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;

      ::placeholder {
        color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
      }
    }
  }

  &:focus-within {
    /* background: ${({ theme }) => theme[COLOR_ENUM.INPUT]}; */
  }

  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};

  padding: ${Spacing(4)};
`;
