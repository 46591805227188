import React from 'react';
import { FormikValues } from 'formik';

import { i18n } from '../../lib/lang';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';

export const Component: React.FC<{
  formik: FormikValues;
  isSubmitDisabled: Function;
  isError?: boolean;
  isSuccess?: boolean;
  errorMessage?: string;
}> = ({ formik, isSubmitDisabled, isError, errorMessage, isSuccess }) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      <ButtonElem
        type="submit"
        tid="COMMON.BUTTON.SAVE"
        disabled={isSubmitDisabled()}
        onClick={formik.handleSubmit}
      />
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
    </FormElem>
  );
};
