import React from 'react';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import backgroundImg from '../../asset/svg/background-gradient.svg';

export const Elem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <LayoutPage>{children}</LayoutPage>;
};

const LayoutPage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  gap: ${Spacing(5)};
  padding: ${Spacing(3)} ${Spacing(3)} 105px ${Spacing(3)};

  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;
