import {
  DATA_INTER,
  WITHDRAW_ITEM_DATA_RAW_INTER,
  WITHDRAW_STATUS_VIEW,
} from './constant';
import { convertDateToListTimezone } from '../../lib/lang/service';

export const convert = (list: WITHDRAW_ITEM_DATA_RAW_INTER[]): DATA_INTER => {
  const converedData = list.map((e) => {
    const convert = {
      ...e,
      createDate: convertDateToListTimezone(e.createDate),
      status: {
        value: e.status,
        label: WITHDRAW_STATUS_VIEW[e.status],
      },
    };

    return convert;
  });

  return { list: converedData, isEmpty: list?.length < 1 };
};
