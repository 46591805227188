import React from 'react';

import styled from 'styled-components';
import billSvg from '../../asset/svg/common/bill.svg';
import { Spacing } from '../../theme';
import { TextElem } from '../text';

export const Elem: React.FC<{ titleTid: string; descriptionTid: string }> = ({
  titleTid,
  descriptionTid,
}) => {
  return (
    <Container>
      <ImgStyled src={billSvg} />
      <TextContainer>
        <TextElem color="white" size="medium" type="bold" tid={titleTid} />
        <TextElem
          color="textSecondary"
          size="semiSmall"
          type="medium"
          tid={descriptionTid}
        />
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  align-items: center;
  margin-top: ${Spacing(4)};
`;

const ImgStyled = styled.img`
  width: 40px;
  height: 40px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  align-items: center;
  justify-content: center;
  text-align: center;
`;
