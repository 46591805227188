import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { COLOR_ENUM } from '../../theme/color';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { Spacing } from '../../theme';
import closeSvg from '../../asset/svg/close.svg';

export const Elem: React.FC<{
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}> = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={closeSvg} alt="close icon" />
        </CloseButton>
        {children}
      </ModalContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  min-height: 100vh;
  height: 100%;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${Spacing(12)} ${Spacing(5)};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${Spacing(3)};
  left: ${Spacing(5)};
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  transition: all ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.BUTTON]};
  }
`;
