import React, { useEffect, useState } from 'react';

import { Component } from './component';
import { useQuery, useQueryClient } from 'react-query';
import { EARNING_USER_LIST_MODULE_NAME } from '../earning-create/constant';
import { convertUser } from '../earning-create/convert';
import { ACTION_RESPONSE_INTER } from '../user-list/constant';
import { WITHDRAW_LIST_MODULE_NAME } from '../withdraw-list';
import { getUserListShort } from '../../data/user/action';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';

export const Container: React.FC<{
  setPayload: React.Dispatch<
    React.SetStateAction<
      | {
          status: string;
          type?: string;
          userId?: string | number;
          startDate?: string;
          endDate?: string;
        }
      | undefined
    >
  >;
}> = ({ setPayload }) => {
  const [activeTab, setActiveTab] = useState('All');
  const [userId, setUserId] = useState<string | undefined>();
  const [type, setType] = useState<string | undefined>();
  const [date, setDate] = useState<[string, string] | []>([]);
  const [selectUserValue, setSelectUsertValue] = useState('');

  const queryClient = useQueryClient();

  const preFetchUser = useQuery(USER_LIST_SHORT_MODULE_NAME, () =>
    getUserListShort({ search: selectUserValue }),
  );

  const getData = (): any => {
    const data = preFetchUser.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      const convertData = convertUser(data);

      return { ...convertData, isEmpty: data?.list?.length < 1 };
    }
  };

  const userOptionList = getData()?.list;

  const invalidateCache = async () => {
    await queryClient.invalidateQueries([WITHDRAW_LIST_MODULE_NAME]);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchUser.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectUserValue]);

  const handleSelectUserChange = (newValue: any, _actionMeta: any) => {
    setSelectUsertValue(newValue);
  };

  useEffect(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      status: activeTab,
      userId: userId,
      type,
      startDate: date[0],
      endDate: date[1],
    }));
    invalidateCache();
  }, [activeTab, userId, date, type]);

  return (
    <Component
      handleSelectUserChange={handleSelectUserChange}
      userList={userOptionList}
      setUserId={setUserId}
      setType={setType}
      setDate={setDate}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};
