export const clipboardCopy = (value: string) => {
  const textarea = document.createElement('textarea');
  textarea.value = value;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    alert(`Не удалось скопировать данные: ${err}`);
  }

  // Удаляем временный textarea элемент
  document.body.removeChild(textarea);
};
