import React from 'react';
import styled from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { GridElem } from '../../common/grid';
import { DATA_INTER } from './constant';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { TextElem } from '../../common/text';
import { SliderCarousel } from './frame/slider';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage }) => {
  return (
    <>
      {isSuccess && data?.list && !data.isEmpty && (
        <GridElem spacing={4}>
          <TextElem
            tid="USER.GIFT.TITLE"
            type="semi-bold"
            color="white"
            size="semiSmall"
          />
          {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
          <GridElemStyled spacing={5}>
            <SliderCarousel list={data.list} />
          </GridElemStyled>
          {isLoading && (
            <>
              <Divider />
              <SkeletonDataStyled />
            </>
          )}
        </GridElem>
      )}
    </>
  );
};

const GridElemStyled = styled(GridElem)``;

const SkeletonDataStyled = styled(SkeletonDataElem)`
  margin-top: ${Spacing(6)};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  height: 1px;
  width: 100%;
`;
